export var FieldTrigger;
(function (FieldTrigger) {
    FieldTrigger["OnValidate"] = "http://platform.cosmoconsult.com/ontology/OnValidate";
    FieldTrigger["OnLookup"] = "http://platform.cosmoconsult.com/ontology/OnLookup";
    FieldTrigger["OnInputBlur"] = "http://platform.cosmoconsult.com/ontology/OnInputBlur";
    FieldTrigger["OnMonacoGetType"] = "http://platform.cosmoconsult.com/ontology/OnMonacoGetType";
})(FieldTrigger || (FieldTrigger = {}));
export var TypeTrigger;
(function (TypeTrigger) {
    TypeTrigger["OnBeforeCreate"] = "http://platform.cosmoconsult.com/ontology/OnBeforeCreate";
    TypeTrigger["OnBeforeUpdate"] = "http://platform.cosmoconsult.com/ontology/OnBeforeUpdate";
    TypeTrigger["OnBeforeDelete"] = "http://platform.cosmoconsult.com/ontology/OnBeforeDelete";
    TypeTrigger["OnBeforeRead"] = "http://platform.cosmoconsult.com/ontology/OnBeforeRead";
    TypeTrigger["OnAfterCreate"] = "http://platform.cosmoconsult.com/ontology/OnAfterCreate";
    TypeTrigger["OnAfterUpdate"] = "http://platform.cosmoconsult.com/ontology/OnAfterUpdate";
    TypeTrigger["OnAfterDelete"] = "http://platform.cosmoconsult.com/ontology/OnAfterDelete";
    TypeTrigger["OnAfterRead"] = "http://platform.cosmoconsult.com/ontology/OnAfterRead";
    TypeTrigger["OnBeforePublishMajorVersion"] = "http://platform.cosmoconsult.com/ontology/OnBeforePublishMajorVersion";
    TypeTrigger["OnAfterPublishMajorVersion"] = "http://platform.cosmoconsult.com/ontology/OnAfterPublishMajorVersion";
    TypeTrigger["OnBeforeVectorize"] = "http://platform.cosmoconsult.com/ontology/OnBeforeVectorize";
    TypeTrigger["OnBeforeFormInit"] = "http://platform.cosmoconsult.com/ontology/OnBeforeFormInit";
    TypeTrigger["OnBeforeFormSubmit"] = "http://platform.cosmoconsult.com/ontology/OnBeforeFormSubmit";
    TypeTrigger["OnBeforeFormClose"] = "http://platform.cosmoconsult.com/ontology/OnBeforeFormClose";
    TypeTrigger["OnAfterFormClose"] = "http://platform.cosmoconsult.com/ontology/OnAfterFormClose";
    TypeTrigger["OnAfterFormChange"] = "http://platform.cosmoconsult.com/ontology/OnAfterFormChange";
    TypeTrigger["OnAfterFormSubmit"] = "http://platform.cosmoconsult.com/ontology/OnAfterFormSubmit";
    TypeTrigger["OnAfterUiItemRead"] = "http://platform.cosmoconsult.com/ontology/OnAfterUiItemRead";
    TypeTrigger["OnBeforeUiDrop"] = "http://platform.cosmoconsult.com/ontology/OnBeforeUiDrop";
    TypeTrigger["OnBeforeUiCreate"] = "http://platform.cosmoconsult.com/ontology/OnBeforeUiCreate";
    TypeTrigger["OnBeforeUiUpdate"] = "http://platform.cosmoconsult.com/ontology/OnBeforeUiUpdate";
    TypeTrigger["OnBeforeUiDelete"] = "http://platform.cosmoconsult.com/ontology/OnBeforeUiDelete";
    TypeTrigger["OnBeforeUiUserMachineTranslate"] = "http://platform.cosmoconsult.com/ontology/OnBeforeUiUserMachineTranslate";
})(TypeTrigger || (TypeTrigger = {}));
var IdmAccountApprovalStatus;
(function (IdmAccountApprovalStatus) {
    IdmAccountApprovalStatus["Pending"] = "pending";
    IdmAccountApprovalStatus["Approved"] = "approved";
})(IdmAccountApprovalStatus || (IdmAccountApprovalStatus = {}));
