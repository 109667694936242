import { configureAxios, IAxiosInstance, isCancelError } from '@cpa/base-http';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { i18n } from '../app';
import { generateJourneyUrl, generateRedirectState, signIn } from '../helpers';
import { finishLoading, startLoading } from '../store/app/actions';
import { store } from '../store';
import { BaseApi, IMatchedDataEndpoint } from '../types';
import { getAppBasePath } from '../helpers/location';
import { Environment } from '../app/environment';

export interface IMatchedDataEndpointWithAxios extends IMatchedDataEndpoint {
  axios: IAxiosInstance;
  axiosWithoutCancelInterceptor: IAxiosInstance;
}

export const wrapAxiosWithLoadingInterceptors = (instance: IAxiosInstance, disableCancelInterceptor?: boolean): IAxiosInstance => {
  instance.interceptors.request.use(
    async (req) => {
      store.dispatch(startLoading());
      return req;
    },
    (error) => {
      return error;
    }
  );

  instance.interceptors.response.use(
    (res) => {
      store.dispatch(finishLoading());
      return res;
    },
    (error) => {
      store.dispatch(finishLoading());
      if (!disableCancelInterceptor && isCancelError(error)) {
        return new Promise(() => {});
      }

      throw error;
    }
  );

  return instance;
};

export async function getAccessToken(): Promise<string> {
  const { app, auth } = store.getState();
  if (!app.msalProvider || !app.cpa?.configuration) {
    throw new Error('Cannot get access token, auth is not initialized');
  }

  if (!auth.user?.authenticationResult.account?.homeAccountId) {
    throw new Error('Cannot get access token, no user');
  }
  const accountInfo = app.msalProvider.getAccountByHomeId(auth.user.authenticationResult.account.homeAccountId);
  if (!accountInfo) {
    throw new Error('Cannot get access token, no account info');
  }

  console.debug('Trying to obtain access token from auth provider');
  try {
    const token = await app.msalProvider.acquireTokenSilent({
      redirectUri: `${window.location.origin}${getAppBasePath()}`,
      account: accountInfo,
      authority: generateJourneyUrl(app.cpa.configuration.msalAuthJourneySignIn, app.cpa.configuration.msalAuthAuthority),
      scopes: app.cpa.configuration.msalAuthScopes ?? [],
    });
    return token.accessToken || token.idToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError || e?.errorCode === 'no_tokens_found' || e?.errorCode === 'monitor_window_timeout') {
      console.error('Failed to acquire token silently. Using redirect.', e);
      const currentLocation = store.getState().router.location;
      await app.msalProvider.acquireTokenRedirect({
        account: accountInfo,
        authority: generateJourneyUrl(app.cpa.configuration.msalAuthJourneySignIn, app.cpa.configuration.msalAuthAuthority),
        scopes: app.cpa.configuration.msalAuthScopes ?? [],
        state: generateRedirectState(currentLocation),
      });
    }
    throw e;
  }
}

export const getLanguage = (): string => {
  const {
    settings: { dataLanguage },
  } = store.getState();

  const language = dataLanguage || i18n.language;
  return language?.split('_')[0];
};

export function createAxiosForDataEndpoint(
  endpoint: IMatchedDataEndpoint,
  noAuth: boolean = false,
  apiKey?: string,
  disableCancelInterceptor?: boolean
): IAxiosInstance {
  const instance = configureAxios(
    endpoint.url,
    /* Temp: Send x-functions-key always to allow usage of BaseApi.DataService for API Gateway if OData is required:
    endpoint.dataType === BaseApi.ApiGateway ? apiKey : undefined */ apiKey,
    async () => {
      try {
        return await getAccessToken();
      } catch (e) {
        if (!noAuth) {
          console.warn('Failed to get access token', e);
        }
        return null;
      }
    },
    getLanguage,
    endpoint.withCredentials === true,
    () => {
      // Auth error handling

      const {
        app: { msalProvider, cpa },
      } = store.getState();
      if (!msalProvider || !cpa?.configuration) {
        console.warn(`Failed to start signin. Auth is not initialized.`);
        return;
      }

      const currentLocation = store.getState().router.location;

      console.debug(`Executing signin`);
      signIn(msalProvider, cpa.configuration.msalAuthScopes ?? [], currentLocation);
    },
    Environment.env.REACT_APP_CPA_NAME
  );

  return wrapAxiosWithLoadingInterceptors(instance, disableCancelInterceptor);
}

const rootDataServiceEndpoint = {
  identifier: 'root-data-service',
  name: 'Root Data Service',
  url: Environment.env.REACT_APP_ROOT_DATA_SERVICE_URL as string,
  dataType: BaseApi.DataService,
  withCredentials: true,
};

export const rootDataService = createAxiosForDataEndpoint(rootDataServiceEndpoint, true);

export const rootDataServiceWithoutCancelInterceptor = createAxiosForDataEndpoint(rootDataServiceEndpoint, true, undefined, true);
