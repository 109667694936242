import { IGlobalState } from '@cpa/base-core/store';
import { Checkbox, ChoiceGroup, DefaultButton, Dropdown, IChoiceGroupOption, IDropdownOption, Toggle } from '@fluentui/react';
import React, { useCallback, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { applySettings, showCookieAgreement } from '@cpa/base-core/store/settings/actions';
import { MatchedCpa } from '@cpa/base-core/types';
import { TableActionsPosition, UserExperiencePreference } from '@cpa/base-core/store/settings/reducer';

import darkBox from '../../../../assets/icons/solid-dark-box.svg';
import lightBox from '../../../../assets/icons/solid-light-box.svg';

import styles from './AppSettings.module.scss';

interface IAppSettingsProps {
  forcedToggleState?: {
    useOnlyHorizontalMenu?: boolean;
  };
  onClose?: () => void;
  onTakeATourClick?: () => void;
}

const AppSettings: React.FC<IAppSettingsProps> = ({ forcedToggleState, onClose, onTakeATourClick }) => {
  const dark = useSelector((state: IGlobalState) => state.settings.darkMode);
  const colorizedRows = useSelector((state: IGlobalState) => state.settings.zebraRows);
  const highContrast = useSelector((state: IGlobalState) => state.settings.highContrast);
  const disableAnimation = useSelector((state: IGlobalState) => state.settings.disableAnimation);
  const surfaceLight = useSelector((state: IGlobalState) => state.settings.surfaceLight);
  const useOnlyHorizontalMenu = useSelector((state: IGlobalState) => state.settings.useOnlyHorizontalMenu);
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  const userExperiencePreference = useSelector((state: IGlobalState) => state.settings.userExperiencePreference);
  const tableActionsPosition = useSelector((state: IGlobalState) => state.settings.tableActionsPosition);
  const settingsForThisAppOnly = useSelector((state: IGlobalState) => state.settings.settingsForThisAppOnly);
  const cpa = useSelector((state: IGlobalState) => state.app.cpa) as MatchedCpa;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onThemeChange = useCallback(
    (e: React.FormEvent<HTMLElement | HTMLInputElement>, option: IChoiceGroupOption) => {
      dispatch(applySettings({ settings: { darkMode: option!.key === 'dark' }, palette: cpa?.colorPalette }));
    },
    [cpa, dispatch]
  );

  const onColorizeRowsToggle = useCallback(() => {
    dispatch(applySettings({ settings: { zebraRows: !colorizedRows } }));
  }, [dispatch, colorizedRows]);

  const onHighContrastToggle = useCallback(() => {
    dispatch(applySettings({ settings: { highContrast: !highContrast }, palette: cpa?.colorPalette }));
  }, [cpa, dispatch, highContrast]);

  const onDisableAnimationToggle = useCallback(() => {
    dispatch(applySettings({ settings: { disableAnimation: !disableAnimation } }));
  }, [disableAnimation, dispatch]);

  const onSurfaceLightToggle = useCallback(() => {
    dispatch(applySettings({ settings: { surfaceLight: !surfaceLight } }));
  }, [surfaceLight, dispatch]);

  const onPowerUserToggle = useCallback(
    (e: React.FormEvent<HTMLElement | HTMLInputElement>, option: IDropdownOption) => {
      dispatch(applySettings({ settings: { userExperiencePreference: option.key } }));
    },
    [dispatch]
  );

  const onTableActionsPositionToggle = useCallback(
    (e: React.FormEvent<HTMLElement | HTMLInputElement>, option: IDropdownOption) => {
      dispatch(applySettings({ settings: { tableActionsPosition: option.key } }));
    },
    [dispatch]
  );

  const onUseOnlyHorizontalMenuToggle = useCallback(() => {
    dispatch(applySettings({ settings: { useOnlyHorizontalMenu: !useOnlyHorizontalMenu } }));
  }, [dispatch, useOnlyHorizontalMenu]);

  const onDataLanguageChange = useCallback(
    (e: React.FormEvent<HTMLElement | HTMLInputElement>, option: IDropdownOption) => {
      dispatch(applySettings({ settings: { dataLanguage: option?.data?.identifier || null } }));
    },
    [dispatch]
  );

  const handleSettingsModeChange = useCallback(
    (e: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      dispatch(applySettings({ settings: { settingsForThisAppOnly: checked } }));
      console.info(checked);
    },
    [dispatch]
  );

  const themes: IChoiceGroupOption[] = useMemo(() => {
    return [
      {
        key: 'light',
        text: t('common.light'),
        imageSrc: lightBox,
        selectedImageSrc: lightBox,
        imageSize: { width: 32, height: 32 },
      },
      {
        key: 'dark',
        text: t('common.dark'),
        imageSrc: darkBox,
        selectedImageSrc: darkBox,
        imageSize: { width: 32, height: 32 },
      },
    ];
  }, [t]);

  const allLocales = useSelector((state: IGlobalState) => state.app.allLocales);
  const dropdownOptions = useMemo(() => {
    return [
      {
        key: 'notSelected',
        text: t('common.notSelected'),
      },
      ...allLocales.map((locale) => ({
        key: locale?.identifier,
        text: locale?.name,
        data: locale,
      })),
    ];
  }, [t, allLocales]);

  const userDropdownOptions = useMemo(() => {
    return [
      {
        key: UserExperiencePreference.Simple,
        text: t('header.controls.settings.simplified'),
      },
      {
        key: UserExperiencePreference.Expert,
        text: t('header.controls.settings.advanced'),
      },
      {
        key: UserExperiencePreference.Undefined,
        text: t('common.notSet'),
      },
    ];
  }, [t]);

  const tablePositionDropdownOptions = useMemo(() => {
    return [
      {
        key: TableActionsPosition.Left,
        text: t('header.controls.settings.left'),
      },
      {
        key: TableActionsPosition.Right,
        text: t('header.controls.settings.right'),
      },
      {
        key: TableActionsPosition.Undefined,
        text: t('common.notSet'),
      },
    ];
  }, [t]);

  const renderFlag = useCallback((code: string) => {
    return (
      <ReactCountryFlag
        style={{
          width: '30px',
          height: '20px',
          backgroundSize: 'contain',
          marginRight: '8px',
        }}
        countryCode={code}
        svg={true}
      />
    );
  }, []);

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div className={styles.dataLanguageDropdownOption}>
        {!!option.data && !!option.data.icon && renderFlag(option.data.icon)}
        <span className={styles.text}>{option.text}</span>
      </div>
    );
  };

  const onCookieSettingsClick = useCallback(() => {
    dispatch(showCookieAgreement());
    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  const handleTakeATourClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
    onTakeATourClick?.();
  }, [onClose, onTakeATourClick]);

  return (
    <div style={{ marginTop: 30 }}>
      {!cpa.configuration.disableDarkMode && (
        <ChoiceGroup label={t('header.controls.settings.darkMode')} options={themes} onChange={onThemeChange} selectedKey={dark ? 'dark' : 'light'} />
      )}
      <Toggle label={t('header.controls.settings.highContrast')} checked={highContrast} onChange={onHighContrastToggle} />
      {userExperiencePreference !== UserExperiencePreference.Simple && (
        <>
          <Toggle label={t('header.controls.settings.colorizedRows')} checked={colorizedRows} onChange={onColorizeRowsToggle} />
          <Toggle label={t('header.controls.settings.disableAnimation')} checked={disableAnimation} onChange={onDisableAnimationToggle} />
          <Toggle label={t('header.controls.settings.surfaceLight')} checked={surfaceLight} onChange={onSurfaceLightToggle} />
          <Toggle
            label={t('header.controls.settings.useOnlyHorizontalMenu')}
            disabled={forcedToggleState?.useOnlyHorizontalMenu !== undefined}
            checked={forcedToggleState?.useOnlyHorizontalMenu !== undefined ? forcedToggleState?.useOnlyHorizontalMenu : useOnlyHorizontalMenu}
            onChange={onUseOnlyHorizontalMenuToggle}
          />
          <Dropdown
            label={t('header.controls.settings.dataLanguage')}
            options={dropdownOptions}
            onRenderOption={onRenderOption}
            onChange={onDataLanguageChange}
            defaultSelectedKey={dataLanguage || 'notSelected'}
          />
        </>
      )}
      <Dropdown
        label={t('header.controls.settings.userExperience')}
        options={userDropdownOptions}
        onRenderOption={onRenderOption}
        defaultSelectedKey={userExperiencePreference}
        onChange={onPowerUserToggle}
      />
      <Dropdown
        label={t('header.controls.settings.tableActionsPosition')}
        options={tablePositionDropdownOptions}
        onRenderOption={onRenderOption}
        defaultSelectedKey={tableActionsPosition}
        onChange={onTableActionsPositionToggle}
      />
      <Checkbox
        className={styles.settingsMode}
        label={t('header.controls.settings.changesForApp')}
        checked={settingsForThisAppOnly}
        onChange={handleSettingsModeChange}
      />
      <div className={styles.actions}>
        <DefaultButton
          iconProps={{ iconName: 'AuthenticatorApp' }}
          onClick={onCookieSettingsClick}
          text={t('header.controls.settings.cookieSettings')}
        />
        <DefaultButton iconProps={{ iconName: 'Info' }} onClick={handleTakeATourClick} text={t('common.takeATour')} />
      </div>
    </div>
  );
};

export default AppSettings;
