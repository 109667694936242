import {
  BatchOperationItemResult,
  FieldTrigger,
  IJSONSchema,
  IUiTriggerExecutionContext,
  IUiTriggerUtils,
  Json,
  Schemas,
  TypeConstants,
  TypeTrigger,
} from '@cp/base-types';
import { History } from 'history';
import * as _ from 'lodash';
import pizzip from 'pizzip';
// PizZip/utils has no type declaration
// @ts-ignore
import pizzipUtils from 'pizzip/utils';
import {
  cloneDeepWithMetadata,
  DataServiceModules,
  docxTemplater,
  getCollectionNameFromSubjectUri,
  getSubjectLocalizedCacheCollectionName,
  parseOntologyUri,
  resolveSubjectUri,
  shortenSubjectUri,
} from '@cp/base-utils';

import {
  axiosDictionary,
  cloneEntityToEndpoint,
  deleteEntityFromEndpoint,
  executeAggregationTemplate,
  executePromptTemplate,
  getCpFunction,
  getEndpoint,
  getEntitiesFromEndpoint,
  getSchema,
  patchEntityToEndpoint,
  postEntityToEndpoint,
  putEntityToEndpoint,
} from '../../api';
import { IGlobalState, store } from '../../store';
import { i18n } from '../../app';
import { GlobalDialogType, GlobalDrawerType, IGlobalDialogStyles } from '../../types';
import { showDialog, showDrawer } from '../ui';
import history from '../../app/history';
import { getLocalized } from '../culture/getLocalized';
import notification from '../toast';
import { Environment } from '../../app/environment';
import { getUserJwtDetails } from '../jwt-details';
import { transformCode } from '../swc';
import { getSvg } from '../bpmnio';

import { prepareActionLink, replaceByPattern } from './data';
import { floorNumber, formatDate, formatDateTime, formatStringOrNumber } from './formatters';
import { getSafeString } from './html';

const triggerUtils: IUiTriggerUtils = {
  get language(): string {
    return i18n.language;
  },
  get globalState(): IGlobalState {
    return store.getState();
  },
  get location(): Location {
    return window.location;
  },
  get history(): History<unknown> {
    return history;
  },
  get i18n(): typeof i18n {
    return i18n;
  },
  typescript: {
    transform: async (code: string) => {
      return transformCode(code);
    },
  },
  localStorage: window.localStorage,
  format: {
    floorNumber,
    formatDate,
    formatDateTime,
    formatStringOrNumber,
  },
  misc: {
    getSafeString,
    prepareActionLink,
    replaceByPattern,
    cloneDeepWithMetadata,
    resolveSubjectUri,
    shortenSubjectUri,
    parseOntologyUri,
    getCollectionNameFromSubjectUri,
    getSubjectLocalizedCacheCollectionName,
  },
  http: {
    get: getEntitiesFromEndpoint,
    post: postEntityToEndpoint,
    put: putEntityToEndpoint,
    patch: patchEntityToEndpoint,
    delete: deleteEntityFromEndpoint,
    clone: cloneEntityToEndpoint,
  },
  auth: {
    getUserJwtDetails: getUserJwtDetails,
  },
  ui: {
    dialog: {
      show(
        message: string,
        primaryOption: boolean,
        options?: {
          title?: string;
          styles?: IGlobalDialogStyles;
          isHtml?: boolean;
        }
      ): Promise<unknown> {
        return showDialog({
          type: GlobalDialogType.CONFIRMATION,
          message,
          primaryOption,
          title: options?.title,
          styles: options?.styles,
          isHtml: options?.isHtml,
        });
      },
      showMessage(
        message: string,
        options?: {
          title?: string;
          styles?: IGlobalDialogStyles;
          isHtml?: boolean;
        }
      ): Promise<unknown> {
        return showDialog({
          type: GlobalDialogType.MESSAGE,
          message,
          primaryButtonText: 'common.ok',
          title: options?.title ?? 'common.information',
          styles: options?.styles,
          isHtml: options?.isHtml,
        });
      },
      showInput(
        message: string,
        options?: {
          title?: string;
          optional?: boolean;
          initialValue?: string;
          styles?: IGlobalDialogStyles;
          isHtml?: boolean;
        }
      ): Promise<unknown> {
        return showDialog({
          type: GlobalDialogType.INPUT,
          message,
          primaryButtonText: 'common.submit',
          secondaryButtonText: 'common.close',
          title: options?.title ?? 'common.information',
          dialogTypeOptions: options,
          styles: options?.styles,
          isHtml: options?.isHtml,
        });
      },
      showOptions(
        message: string,
        options: string[],
        dialogOptions?: { title?: string; initialValue?: string; styles?: IGlobalDialogStyles; isHtml?: boolean }
      ): Promise<unknown> {
        return showDialog({
          type: GlobalDialogType.OPTIONS,
          message,
          primaryButtonText: 'common.submit',
          secondaryButtonText: 'common.close',
          title: dialogOptions?.title,
          dialogTypeOptions: { options, initialValue: dialogOptions?.initialValue },
          styles: dialogOptions?.styles,
          isHtml: dialogOptions?.isHtml,
        });
      },
      showItemSelection(
        message: string,
        dataPageDetails: object,
        options?: { title?: string; styles?: IGlobalDialogStyles; isHtml?: boolean }
      ): Promise<unknown> {
        return showDialog({
          type: GlobalDialogType.ITEM_SELECTION,
          message,
          primaryButtonText: 'common.submit',
          secondaryButtonText: 'common.close',
          title: options?.title,
          dialogTypeOptions: { ...dataPageDetails },
          styles: options?.styles,
          isHtml: options?.isHtml,
        });
      },
      showForm(
        message: string,
        schema: IJSONSchema,
        options?: {
          title?: string;
          optional?: boolean;
          initialValue?: object;
          styles?: IGlobalDialogStyles;
          width?: string | number;
          isHtml?: boolean;
        }
      ): Promise<unknown> {
        return showDialog({
          type: GlobalDialogType.FORM,
          message,
          primaryButtonText: 'common.submit',
          secondaryButtonText: 'common.close',
          title: options?.title ?? 'common.information',
          dialogTypeOptions: { schema: schema, ...options },
          styles: options?.styles,
          isHtml: options?.isHtml,
          width: options?.width || '50vw',
          closeOnClickOutside: options?.optional,
          dialogContentProps: {
            showCloseButton: !!options?.optional,
          },
        });
      },
    },
    drawer: {
      showDifference(
        drawerOptions: {
          schema: IJSONSchema;
          itemA: object;
          itemB: object;
        },
        isMaximized: boolean
      ): Promise<unknown> {
        return showDrawer({
          type: GlobalDrawerType.DIFFERENCE,
          drawerOptions: drawerOptions,
          isMaximized,
        });
      },
      showBatchedDifference(
        drawerOptions: { schema: IJSONSchema; results: BatchOperationItemResult<any>[] },
        isMaximized: boolean
      ): Promise<unknown> {
        return showDrawer({
          type: GlobalDrawerType.BATCHED_DIFFERENCE,
          drawerOptions,
          isMaximized,
        });
      },
    },
    loader: {
      counter: 0,
      loaderElement: document.querySelector<HTMLElement>('#loader'),
      start(zIndex?: number): void {
        this.counter++;

        if (this.counter > 0 && this.loaderElement) {
          // Show loader
          this.loaderElement.style.display = 'block';
          this.loaderElement.style.opacity = '0.9';
          this.loaderElement.style.backgroundColor = 'rgb(137, 141, 141, 0.7)';
          if (zIndex) {
            this.loaderElement.style.zIndex = `${zIndex}`;
          }
        }
      },
      finish(): void {
        this.counter--;

        if (this.counter <= 0 && this.loaderElement) {
          // Hide loader
          this.loaderElement.style.display = 'none';
          this.loaderElement.style.opacity = '';
          this.loaderElement.style.backgroundColor = '';
          this.loaderElement.style.zIndex = '1000000';
        }
      },
    },
    notification: notification,
  },
  docxTemplater,
  bpmnio: {
    getSvg: getSvg,
  },
  Pizzip: pizzip,
  PizzipUtils: pizzipUtils,
  env: {
    REACT_APP_ROOT_DATA_SERVICE_URL: Environment.env.REACT_APP_ROOT_DATA_SERVICE_URL!,
  },
  getLocalized: getLocalized,
  getEndpoint: getEndpoint,
  executeCpFunction: executeCpFunction,
  executeBackendAction: executeBackendAction,
  executeAggregationTemplate: executeAggregationTemplate,
  executePromptTemplate: executePromptTemplate,
  Json: Json,
  getSchema: getSchema,
};

async function executeCpFunction(
  identifier: string,
  executionContext: IUiTriggerExecutionContext = { event: 'ExecuteCpFunction' }
): Promise<unknown> {
  const fetchedFunctions = (
    await Promise.all(
      getEntitiesFromEndpoint(axiosDictionary.appDataService, `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpFunction)}`, {
        filter: {
          identifier: identifier,
        },
      })
    )
  ).flatMap((response) => response.entities);

  if (fetchedFunctions.length !== 1) {
    throw new Error(`Unexpected functions amount loaded - ${fetchedFunctions.length}`);
  }

  if (!fetchedFunctions[0].sourceCode || typeof fetchedFunctions[0].sourceCode !== 'string') {
    throw new Error(`Missing sourceCode in CpFunction ${identifier}`);
  }

  return await executeUiTrigger(executionContext, fetchedFunctions[0].sourceCode);
}

export async function executeUiTriggers<Context extends IUiTriggerExecutionContext>(executionContext: Context, triggers: string[]): Promise<void> {
  for (const trigger of triggers) {
    await executeUiTrigger(executionContext, trigger);
  }
}

export async function executeUiTrigger<Context extends IUiTriggerExecutionContext>(executionContext: Context, trigger: string): Promise<unknown> {
  try {
    const triggerContext: {
      execute?: (executionContext: Context, utils: IUiTriggerUtils) => unknown;
    } = {};

    const transformedCode = await transformCode(trigger);

    const triggerFunction = new Function('trigger', 'Promise', '_', 'TypeTrigger', 'FieldTrigger', 'TypeConstants', transformedCode);
    triggerFunction(triggerContext, Promise, _, TypeTrigger, FieldTrigger, TypeConstants);

    if (!triggerContext.execute || typeof triggerContext.execute !== 'function') {
      return;
    }

    const executeResult = await Promise.resolve(triggerContext.execute(executionContext, triggerUtils));
    if (executeResult && executeResult instanceof Promise && executeResult.catch) {
      return executeResult.catch((e: Error) => {
        console.warn(`Failed to run ${executionContext.event} trigger`, e);
        throw e;
      });
    }

    return executeResult;
  } catch (e) {
    console.warn(`Failed to run ${executionContext.event} trigger`, e);
    throw e;
  }
}

export async function executeBackendAction(
  endpointIdentifier: string,
  pageIdentifier: string,
  actionIdentifier: string,
  selectedItemsIdentifiers?: string[]
): Promise<{ message?: string | undefined; resetContent?: boolean | undefined }> {
  const endpoint = getEndpoint(endpointIdentifier);
  const response = await endpoint.axios.post<{ message?: string; resetContent?: boolean }>(
    `/${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaPage)}/${encodeURIComponent(
      pageIdentifier
    )}/actions/${encodeURIComponent(actionIdentifier)}/execute`,
    {
      itemIdentifiers: selectedItemsIdentifiers || [],
    }
  );
  return response;
}

export async function executeFrontendAction<Context extends IUiTriggerExecutionContext>(
  action: NonNullable<Schemas.CpaPage['actions']>[0],
  context: Context
): Promise<unknown> {
  if ('sourceCode' in action.function && action.function.sourceCode) {
    // Inline function
    return await executeUiTrigger(context, action.function.sourceCode as string);
  } else if ('identifier' in action.function && action.function.identifier) {
    // Linked CpFunction
    try {
      const { cpFunction } = await getCpFunction(action.function.identifier);
      return await executeUiTriggers(context, [cpFunction.sourceCode]);
    } catch (e) {
      console.error(`Failed to execute Action cpFunction ${action.function.identifier}`, e.message);
    }
  }
  return;
}
