import { Schemas } from '@cp/base-types';

import { IBase } from './base';
import { BaseApi } from './page';

export type MatchedCpa = Schemas.Cpa & {
  configuration: Required<Required<Schemas.Cpa>['configurations']>['productionConfiguration'];
};

export interface IDataEndpoint extends IBase {
  name: string;
  dataType: BaseApi;
  withCredentials?: boolean;
  endpoints: {
    developmentEndpoint: string;
    localEndpoint: string;
    productionEndpoint: string;
    stagingEndpoint: string;
  };
}

export interface IMatchedDataEndpoint extends Omit<IDataEndpoint, 'endpoints'> {
  url: string;
}

export enum SocialMediaIconRef {
  FACEBOOK = 'http://platform.cosmoconsult.com/ontology/Facebook',
  INSTAGRAM = 'http://platform.cosmoconsult.com/ontology/Instagram',
  LINKEDIN = 'http://platform.cosmoconsult.com/ontology/Linkedin',
  TWITTER = 'http://platform.cosmoconsult.com/ontology/Twitter',
  XING = 'http://platform.cosmoconsult.com/ontology/Xing',
  YOUTUBE = 'http://platform.cosmoconsult.com/ontology/Youtube',
  NOT_SELECTED = 'http://platform.cosmoconsult.com/ontology/NotSelected',
}

export enum KpiOp {
  SUM = 'http://platform.cosmoconsult.com/ontology/SumOperation',
  COUNT = 'http://platform.cosmoconsult.com/ontology/CountOperation',
}

export enum ActionSelection {
  ONE = 'http://platform.cosmoconsult.com/ontology/One',
  MANY = 'http://platform.cosmoconsult.com/ontology/Many',
  ANY = 'http://platform.cosmoconsult.com/ontology/Any',
  NONE = 'http://platform.cosmoconsult.com/ontology/none',
}

export interface ICallToAction {
  description: string;
  url?: string;
  showOnDashboards?: IBase[];
}

export interface ICpInlineFunction {
  sourceCode: string;
}

export interface ICpFunction extends IBase {
  name: string;
  sourceCode: string;
}

export interface IAction extends Partial<IBase> {
  function: ICpInlineFunction | IBase;
  selection: ActionSelection;
  name: string;
  runOnBackend?: boolean;
  icon?: string;
  hidden?: boolean;
}

export interface ILanguage extends IBase {
  appLocale: string;
  name: string; // Localizable name
  alternateName: string; // Defined as endonym
  icon?: string;
}
