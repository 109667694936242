import ReactGA from 'react-ga';
import { all, call, fork, put, putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { push } from 'connected-react-router';
import { EnvironmentType, Schemas } from '@cp/base-types';
import { apm } from '@elastic/apm-rum';
import { Location } from 'history';

import { axiosDictionary } from '../../api/constants';
import {
  getCpa,
  getCpNamespaces,
  getDataEndpoints,
  getFooterLinks,
  getLanguages,
  getPages,
  ICpaResponse,
  ICpNamespacesResponse,
  IDataEndpointsResponse,
  IFooterLinksResponse,
  ILanguagesResponse,
  IPagesResponse,
} from '../../api/global';
import { StorageKey, maxAmountOfCards, missingGanScreen, accountPlaceholderPattern } from '../../constants';
import {
  generateMsalProvider,
  generateRedirectState,
  getAppBasePath,
  initializeGA,
  isDefined,
  loadColorTheme,
  signIn,
  signOut,
  startSwcInitialization,
  transformCode,
} from '../../helpers';
import { i18n, initLanguages } from '../../app';
import { applySettings, getStateFromStorage, setPageSettings, settingsChanged } from '../settings/actions';
import { IGlobalState } from '..';
import { getEndpoint } from '../../api';
import { loginError, loginSuccess } from '../auth/actions';
import { MatchedCpa, BaseApi, IPageSetting } from '../../types';
import { Environment } from '../../app/environment';
import { syncSettings } from '../settings/saga';

import {
  appNotReady,
  appReady,
  authReady,
  connectionError,
  languageChanged,
  nameSpaceChange,
  setCpa,
  setDataEndpoints,
  setLocales,
  setNamespaces,
} from './actions';

function* initAuth(): Generator {
  console.debug(`Initializing authentication`);

  try {
    // Init swc
    yield call(startSwcInitialization);

    const [{ languages }, { cpa }, { endpoints }, { namespaces }] = (yield all([
      call(getLanguages),
      call(getCpa),
      call(getDataEndpoints),
      call(getCpNamespaces),
    ])) as [ILanguagesResponse, ICpaResponse, IDataEndpointsResponse, ICpNamespacesResponse];

    yield put(setCpa({ cpa }));

    const matchedCpa = (yield select((store: IGlobalState) => store.app.cpa)) as MatchedCpa;
    if (matchedCpa.configuration.disableDarkMode) {
      yield put(applySettings({ settings: { darkMode: false }, palette: cpa.colorPalette }));
    }

    const highContrast = (yield select((store: IGlobalState) => store.settings.highContrast)) as boolean;
    const darkMode = (yield select((store: IGlobalState) => store.settings.darkMode)) as boolean;
    loadColorTheme(cpa.colorPalette, matchedCpa.configuration.disableDarkMode ? false : darkMode, highContrast);

    yield put(setNamespaces({ namespaces }));

    const matchedMetaServiceEndpoint = endpoints.find(({ identifier }) => identifier === cpa.metaServiceEndpoint?.identifier);
    yield put(
      setDataEndpoints({
        endpoints: matchedMetaServiceEndpoint
          ? [
              ...endpoints,
              {
                ...matchedMetaServiceEndpoint,
                identifier: axiosDictionary.appMetaService,
              },
            ]
          : endpoints,
      })
    );

    // Execute init script
    if (matchedCpa.configuration.initScript) {
      try {
        // Get init script text
        let initScript = matchedCpa.configuration.initScript;

        // Replace template variables with external asset links
        // Templates should be defined as {{<key>}}, where key references an key of 'matchedCpa.configuration.assetFiles'
        if (matchedCpa.configuration.assetFiles) {
          for (const assetFileEntry of matchedCpa.configuration.assetFiles) {
            initScript = initScript.replace(new RegExp('\\' + `{{${assetFileEntry.key}}}`, 'g'), assetFileEntry.value!);
          }
        }

        // Evaluate and execute init script
        const initFunction = new Function((yield call(transformCode, initScript)) as string);
        initFunction();
      } catch (e) {
        console.debug(matchedCpa.configuration.initScript);
        console.error(`Failed to execute cpa init script`, e);
      }
    }

    // Load Languages
    const filteredLanguages = languages.filter(
      (language) =>
        matchedCpa.configuration.supportedCpCultures &&
        matchedCpa.configuration.supportedCpCultures.some((lng) => lng.identifier === language.identifier)
    );
    yield put(setLocales({ locales: filteredLanguages, allLocales: languages }));

    // Create MSAL
    const msalProvider = generateMsalProvider({
      journeySignIn: matchedCpa.configuration.msalAuthJourneySignIn,
      clientId: matchedCpa.configuration.msalAuthClientId,
      authority: matchedCpa.configuration.msalAuthAuthority,
      redirectLocation: window.location.origin,
      authorityMetadata: matchedCpa.configuration.msalAuthAuthorityMetadata,
    });

    try {
      // Check if we signed in
      let authenticationResult = (yield call(msalProvider.handleRedirectPromise.bind(msalProvider))) as AuthenticationResult | null;
      if (!authenticationResult) {
        // No auth in progress? Check cached
        const account = msalProvider.getAllAccounts()[0];
        if (account) {
          // Okay, it's cached. Trying to obtain.
          console.debug('Found cached token. Trying to obtain silently.');
          try {
            authenticationResult = (yield call(msalProvider.acquireTokenSilent.bind(msalProvider), {
              redirectUri: `${window.location.origin}${getAppBasePath()}`,
              account: account,
              scopes: matchedCpa.configuration.msalAuthScopes ?? [],
            })) as AuthenticationResult;
          } catch (e) {
            if (e instanceof InteractionRequiredAuthError || e?.errorCode === 'no_tokens_found' || e?.errorCode === 'monitor_window_timeout') {
              console.error('Failed to acquire token silently. Using redirect.', e);
              const currentLocation = (yield select((state: IGlobalState) => state.router.location)) as Location;

              yield call(msalProvider.acquireTokenRedirect.bind(msalProvider), {
                account: account,
                scopes: matchedCpa.configuration.msalAuthScopes ?? [],
                state: generateRedirectState(currentLocation),
              });
              return;
            } else if (e?.errorCode === 'interaction_in_progress') {
              console.debug('Try to run 2 interactions in the same time. Ignoring.');
              return;
            } else {
              throw e;
            }
          }
        }
      }

      yield put(
        authReady({
          msalProvider,
        })
      );

      if (authenticationResult && authenticationResult.account) {
        // We are signed in for sure.
        console.debug(`Hi, ${authenticationResult.account.name}!`, authenticationResult);
        // Set CpAuthToken cookie. Used for server-side authentication.
        document.cookie = `CpAuthToken=${authenticationResult.idToken}; Max-Age=86400; path=/; SameSite=None; Secure`;
        yield put(loginSuccess(authenticationResult));

        // Set user data in elastic apm
        const user = (yield select((store: IGlobalState) => store.auth.user)) as IGlobalState['auth']['user'];
        apm.setUserContext({
          id: user?.account.accountIdentifier,
          username: user?.account.name,
          email: user?.account.email,
        });
        apm.setCustomContext({
          companies: user?.companies
            .map((ca) => {
              return ca.accounts
                .filter((a) => a?.type !== 'Contact' && a?.type.length > 0 && a?.no && a?.no.length > 0)
                .map((a) => {
                  return `${ca?.company?.replace(/\s|:/gi, '_')}:${a?.type?.replace(/\s|:/gi, '_')}:${a?.no?.replace(/\s|:/gi, '_')}`;
                });
            })
            .flat(1)
            .join(','),
        });
      }

      const initialQuery = new URLSearchParams(location.search);
      if (authenticationResult?.state || initialQuery.has('state')) {
        const redirectedState = JSON.parse(atob(authenticationResult?.state || initialQuery.get('state')!));
        yield put(push(redirectedState.pathname));
      }

      const query = new URLSearchParams(location.search);
      if (location.pathname === '/external-sign-in' && query.has('redirect-url')) {
        if (authenticationResult && authenticationResult.account) {
          // We are signed in already, just redirect
          location.href = query.get('redirect-url')!;
          return;
        } else {
          // We need to force sign in and come back here
          const currentLocation = (yield select((state: IGlobalState) => state.router.location)) as Location;
          yield call(signIn, msalProvider, matchedCpa.configuration.msalAuthScopes ?? [], currentLocation);
          return;
        }
      }

      if (location.pathname === '/external-sign-out' && query.has('redirect-url')) {
        if (authenticationResult && authenticationResult.account) {
          // Need to sign out and come back here
          const currentLocation = (yield select((state: IGlobalState) => state.router.location)) as Location;
          yield call(signOut, msalProvider, authenticationResult, currentLocation);
          return;
        } else {
          // We are signed out already, just redirect
          location.href = query.get('redirect-url')!;
          return;
        }
      }
    } catch (e) {
      if (e?.errorCode === 'silent_sso_error') {
        console.debug(`Auth error ${e?.errorCode}, forcing login redirect`, e);
        const currentLocation = (yield select((state: IGlobalState) => state.router.location)) as Location;
        yield call(signIn, msalProvider, matchedCpa.configuration.msalAuthScopes ?? [], currentLocation);
        return;
      }

      // Something went wrong
      console.debug(`Unhandled auth error ${e?.errorCode}`, e);

      yield put(loginError(e));
      yield put(
        authReady({
          msalProvider,
        })
      );
      return;
    }
    yield initApp();
  } catch (e) {
    // Handle connection error
    console.error(e);
    yield put(connectionError({ errorCode: e?.originalError?.response ? e.originalError.response.status : 0 }));
  }
}

function* initApp(): Generator {
  // Init i18n
  const locales = (yield select((store: IGlobalState) => store.app.locales)) as IGlobalState['app']['locales'];
  yield call(initLanguages, locales);

  // Settings
  const derivedStateFromStorage: Partial<Record<string, unknown>> = Object.values(StorageKey).reduce((acc, storageKey) => {
    let parsedValue: unknown;
    try {
      parsedValue = JSON.parse(String(window?.localStorage.getItem(storageKey)));
    } catch (e) {
      parsedValue = window?.localStorage.getItem(storageKey);
    }

    return {
      ...acc,
      [storageKey]: parsedValue,
    };
  }, {});
  yield put(getStateFromStorage(derivedStateFromStorage));

  // GA
  if (derivedStateFromStorage[StorageKey.AcceptGA] || derivedStateFromStorage[StorageKey.AcceptGAWithUserId]) {
    const user = (yield select((store: IGlobalState) => store.auth.user)) as IGlobalState['auth']['user'];

    // Init GA
    const matchedCpa = (yield select((store: IGlobalState) => store.app.cpa)) as MatchedCpa;
    if (matchedCpa.configuration.googleAnalyticsTrackingId) {
      initializeGA(
        matchedCpa.configuration.googleAnalyticsTrackingId,
        Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Local || Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Dev
      );

      if (derivedStateFromStorage[StorageKey.AcceptGAWithUserId]) {
        // Important: It's forbidden by Google's terms of service to overhand PPI (personally identifiable information)
        // https://developers.google.com/analytics/solutions/crm-integration#user_id

        // GA User Id
        ReactGA.set({ userId: user?.account?.accountIdentifier });
        // GA Dimension 1
        if (user?.account?.email) {
          const emailRegEx = /^[a-zA-Z0-9.!#$%&'^_`{}~-]+@([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+))*$/;
          const matchArr = user.account.email.match(emailRegEx);
          if (matchArr && matchArr.length === 2) {
            ReactGA.set({ dimension1: matchArr[1] }); // Use only company's domain or (n-ld).sld.tld
          }
        }
        // GA Dimension 2
        ReactGA.set({
          dimension2: user?.companies
            .map((ca) => {
              return ca.accounts
                .filter((a) => a?.type !== 'Contact' && a?.type.length > 0 && a?.no && a?.no.length > 0)
                .map((a) => {
                  return `${ca?.company?.replace(/\s|:/gi, '_')}:${a?.type?.replace(/\s|:/gi, '_')}:${a?.no?.replace(/\s|:/gi, '_')}`;
                });
            })
            .flat(1)
            .join(',')
            .substr(0, 150),
        });
        // GA Dimension 3 (reseved; see below)
      }
      // GA Dimension 3
      ReactGA.set({ dimension3: localStorage?.getItem('i18nextLng') || undefined });
    }
  }
}

function* loadEntitiesSaga(): Generator {
  try {
    yield put(appNotReady());

    const [{ pages }, { footerLinks }] = (yield all([call(getPages, i18n.language), call(getFooterLinks, i18n.language), call(syncSettings)])) as [
      IPagesResponse,
      IFooterLinksResponse
    ];
    const user = (yield select((store: IGlobalState) => store.auth.user)) as IGlobalState['auth']['user'];
    const userSettings = (yield select((store: IGlobalState) => store.auth.userSettings)) as IGlobalState['auth']['userSettings'];
    const cpa = (yield select((store: IGlobalState) => store.app.cpa)) as IGlobalState['app']['cpa'];

    const pagesConfig: { [key: string]: IPageSetting } = {};

    for (const page of pages) {
      const matchingCpaUserPageSettings = userSettings?.cpaPageUserConfigurations?.find(
        // @ts-ignore
        (settings) => settings?.cpaPage?.identifier === page.identifier && settings?.cpa?.identifier === cpa?.identifier
      );

      // Read from localstorage is required to not lose user settings, it will be removed in the future
      const showOnDashboards = localStorage.getItem(`${page.identifier}.showOnDashboards`);
      const displayFilter = localStorage.getItem(`${page.identifier}.displayFilter`);
      const displayChart = localStorage.getItem(`${page.identifier}.displayChart`);
      const displayTable = localStorage.getItem(`${page.identifier}.displayTable`);
      const facetFiltersWidth = localStorage.getItem(`${page.identifier}.facetFiltersWidth`);
      const disabledColumns = localStorage.getItem(`${page.identifier}.disabledColumns`);
      const customView = localStorage.getItem(`${page.identifier}.customView`);
      const displayAsCards = localStorage.getItem(`${page.identifier}.displayAsCards`);
      const widgetCustomView = localStorage.getItem(`${page.identifier}.widgetCustomView`);
      const widgetDisplayTable = localStorage.getItem(`${page.identifier}.widgetDisplayTable`);
      const widgetDisabledColumns = localStorage.getItem(`${page.identifier}.widgetDisabledColumns`);
      const widgetDisplayAsCards = localStorage.getItem(`${page.identifier}.widgetDisplayAsCards`);
      const amountOfColumns = localStorage.getItem(`${page.identifier}.amountOfColumns`);
      const chartOptions = localStorage.getItem(`${page.identifier}.chartOptions`);

      // First we consider settings from UserProfile then from localstorage
      pagesConfig[page.identifier!] = {
        showOnDashboards:
          (matchingCpaUserPageSettings?.displayOnDashboards?.map((item) => item.identifier).filter(Boolean) as string[] | null) || showOnDashboards
            ? JSON.parse(showOnDashboards as string)
            : page.showOnDashboards?.map(({ identifier }) => identifier).filter(Boolean) || [],
        displayTable: isDefined(matchingCpaUserPageSettings?.displayTable)
          ? !!matchingCpaUserPageSettings?.displayTable
          : displayTable === null || displayTable === 'true',
        displayChart: isDefined(matchingCpaUserPageSettings?.displayChart)
          ? !!matchingCpaUserPageSettings?.displayChart
          : displayChart === null
          ? !!page?.chart && !page?.initialHideChartOnPage
          : displayChart === 'true',
        displayFilter: isDefined(matchingCpaUserPageSettings?.displayFilter)
          ? !!matchingCpaUserPageSettings?.displayFilter
          : displayFilter === null || displayFilter === 'true',
        facetFiltersWidth: matchingCpaUserPageSettings?.facetFiltersWidth
          ? matchingCpaUserPageSettings.facetFiltersWidth
          : facetFiltersWidth
          ? +facetFiltersWidth
          : undefined,
        disabledColumns: matchingCpaUserPageSettings?.disabledColumns
          ? matchingCpaUserPageSettings.disabledColumns
          : disabledColumns
          ? JSON.parse(disabledColumns)
          : [],
        customView: isDefined(matchingCpaUserPageSettings?.customView)
          ? !!matchingCpaUserPageSettings?.customView
          : customView === null || customView === 'true',
        displayAsCards: isDefined(matchingCpaUserPageSettings?.displayAsCards)
          ? !!matchingCpaUserPageSettings?.displayAsCards
          : displayAsCards === null
          ? !!page.customCardTemplate
          : displayAsCards === 'true',
        widgetCustomView: isDefined(matchingCpaUserPageSettings?.widgetCustomView)
          ? !!matchingCpaUserPageSettings?.widgetCustomView
          : widgetCustomView === null
          ? false
          : widgetCustomView === 'true',
        widgetDisplayTable: isDefined(matchingCpaUserPageSettings?.widgetDisplayTable)
          ? !!matchingCpaUserPageSettings?.widgetDisplayTable
          : widgetDisplayTable === null
          ? false
          : widgetDisplayTable === 'true',
        widgetDisabledColumns: matchingCpaUserPageSettings?.widgetDisabledColumns
          ? matchingCpaUserPageSettings.widgetDisabledColumns
          : widgetDisabledColumns
          ? JSON.parse(widgetDisabledColumns)
          : [],
        widgetDisplayAsCards: isDefined(matchingCpaUserPageSettings?.widgetDisplayAsCards)
          ? !!matchingCpaUserPageSettings?.widgetDisplayAsCards
          : widgetDisplayAsCards === null
          ? !!page.customCardTemplate
          : widgetDisplayAsCards === 'true',
        amountOfColumns: isDefined(matchingCpaUserPageSettings?.amountOfColumns)
          ? matchingCpaUserPageSettings!.amountOfColumns
          : amountOfColumns === null
          ? page.maxNoOfCardsInSlider || maxAmountOfCards
          : Number(amountOfColumns),
        chartOptions: isDefined(matchingCpaUserPageSettings?.chartOptions)
          ? JSON.parse(matchingCpaUserPageSettings!.chartOptions!)
          : chartOptions && chartOptions !== 'undefined'
          ? JSON.parse(chartOptions)
          : undefined,
      };
    }

    yield put(setPageSettings({ pages: pagesConfig }));

    const resolvedPages =
      !user || (user && user.gan)
        ? pages
        : pages.map((page: Schemas.CpaPage) => {
            // If endpoint is ApiGateway and there is an account in url
            const isShowMissingGanScreen =
              !!page.dataEndpoint?.identifier &&
              getEndpoint(page.dataEndpoint.identifier).dataType === BaseApi.ApiGateway &&
              !!page.dataUrl?.match(accountPlaceholderPattern);
            return isShowMissingGanScreen
              ? {
                  ...page,
                  customTemplate: {
                    identifier: missingGanScreen,
                  },
                  showOnDashboards: [],
                  kpis: [],
                }
              : page;
          });

    yield putResolve(appReady({ pages: resolvedPages, footerLinks }));
  } catch (e) {
    // Handle connection error
    console.error(e);
    yield put(connectionError({ errorCode: e?.originalError?.response ? e.originalError.response.status : 0 }));
  }
}

function* changeLanguageSaga(): Generator {
  const { languages } = (yield call(getLanguages)) as ILanguagesResponse;

  const matchedCpa = (yield select((store: IGlobalState) => store.app.cpa)) as MatchedCpa;

  // Load Languages
  const filteredLanguages = languages.filter(
    (language) =>
      matchedCpa.configuration.supportedCpCultures &&
      matchedCpa.configuration.supportedCpCultures.some((lng) => lng.identifier === language.identifier)
  );
  yield put(setLocales({ locales: filteredLanguages, allLocales: languages }));

  yield fork(loadEntitiesSaga);
}

function* nameSpaceChangeSaga(): Generator {
  const { namespaces } = (yield call(getCpNamespaces)) as ICpNamespacesResponse;
  yield put(setNamespaces({ namespaces }));
}

function* loadSettings() {
  yield put(settingsChanged());
}

export default function* (): Generator {
  console.debug(`Starting app saga`);

  yield takeEvery(authReady, loadSettings);

  // On language change
  yield takeLatest(languageChanged, changeLanguageSaga);

  yield takeEvery(nameSpaceChange, nameSpaceChangeSaga);

  // Start init
  yield fork(initAuth);
}
