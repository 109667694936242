import React, { useMemo } from 'react';
import { IDataItem } from '@cpa/base-core/types';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';
import { Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './MessageTitle.module.scss';

interface IMessageTitleProps {
  item: IDataItem;
  srcFieldName: string | undefined;

  // Used only to display inside badge
  srcFieldValue: string;
}

const MessageTitle: React.FC<IMessageTitleProps> = ({ item, srcFieldName, srcFieldValue }) => {
  const [t] = useTranslation();
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.email);

  const isRead = useMemo(() => {
    return item?.['__read'];
  }, [item]);
  const directionIcon = useMemo(() => {
    return <Icon className={styles.icon} iconName={item.createdByUser !== user ? 'ArrowDownRight8' : 'ArrowUpRightMirrored8'} />;
  }, [item.createdByUser, user]);

  return (
    <div className={styles.wrapper}>
      {directionIcon}
      <HoverTooltip content={t('common.unread')}>{!isRead ? <div className={styles.unreadMark}></div> : null}</HoverTooltip>
      <span>{srcFieldValue}</span>
    </div>
  );
};

export default MessageTitle;
