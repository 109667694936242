import { generateMsalProvider, generateRedirectState, isTouchDevice } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { IDarkMode } from '@cpa/base-core/types';
import { DefaultButton, IContextualMenuProps, IPersonaProps, IPersonaSharedProps, Persona } from '@fluentui/react';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import urlJoin from 'url-join';
import { push } from 'connected-react-router';

import styles from './ProfilePopup.module.scss';

interface IProfileProps extends IDarkMode {
  profile: IPersonaSharedProps;
  t: (key: string) => string;
  passwordResetLink: string;
  linkAccountLink: string;
}

const defaultScopes: [] = [];

const ProfilePopup: React.FC<IProfileProps> = ({ profile, darkMode, t, passwordResetLink, linkAccountLink }) => {
  const scopes = useSelector((state: IGlobalState) => state.auth.user?.authenticationResult.scopes) || defaultScopes;
  const currentLocation = useSelector((state: IGlobalState) => state.router.location);
  const matchedCpa = useSelector((store: IGlobalState) => store.app.cpa);
  const dispatch = useDispatch();
  const user = useSelector((state: IGlobalState) => state.auth.user);

  const allPages = useSelector((state: IGlobalState) => state.app.pages);
  const userProfilePage = allPages.find((page) => page.identifier === 'global_user-profile' && page.allowModify && page.path);
  const userProfileEditPath =
    userProfilePage && user?.account.email ? urlJoin(userProfilePage.path!, encodeURIComponent(user.account.email), '?action=edit') : undefined;

  const customJourneyMsalProvider = useMemo(() => {
    if (!matchedCpa) {
      return null;
    }

    return generateMsalProvider({
      journeySignIn: matchedCpa.configuration.msalAuthJourneySignIn,
      clientId: matchedCpa.configuration.msalAuthClientId,
      authority: matchedCpa.configuration.msalAuthAuthority,
      redirectLocation: window.location.origin,
    });
  }, [matchedCpa]);

  const onEditClick = useCallback(
    (ev?: React.MouseEvent<unknown> | React.KeyboardEvent) => {
      ev?.preventDefault();

      if (!userProfileEditPath) {
        return;
      }

      console.debug(`Edit profile. Redirecting user to ${userProfileEditPath}.`);
      dispatch(push(userProfileEditPath));
    },
    [dispatch, userProfileEditPath]
  );

  const onResetClick = useCallback(() => {
    console.debug(`Reset password. Redirecting user to ${passwordResetLink}.`);

    customJourneyMsalProvider?.loginRedirect({
      scopes: scopes,
      authority: passwordResetLink,
      state: generateRedirectState(currentLocation),
    });
  }, [passwordResetLink, customJourneyMsalProvider, scopes, currentLocation]);

  const onLinkClick = useCallback(() => {
    console.debug(`Link account. Redirecting user to ${linkAccountLink}.`);

    customJourneyMsalProvider?.loginRedirect({
      scopes: scopes,
      authority: linkAccountLink,
      state: generateRedirectState(currentLocation),
    });
  }, [linkAccountLink, customJourneyMsalProvider, scopes, currentLocation]);

  const editMenuProps: IContextualMenuProps = useMemo(() => {
    const touchDevice: boolean = isTouchDevice();
    const items: IContextualMenuProps['items'] = [
      {
        key: 'resetPassword',
        text: t('header.controls.profile.resetPassword'),
        onClick: onResetClick,
        iconProps: { iconName: 'AccountActivity' },
      },
      {
        key: 'linkAccount',
        text: t('header.controls.profile.linkAccount'),
        onClick: onLinkClick,
        iconProps: { iconName: 'UserFollowed' },
      },
    ];

    if (touchDevice) {
      items.unshift({
        key: 'editAccount',
        text: t('header.controls.profile.edit'),
        onClick: onEditClick,
        iconProps: { iconName: 'ContactCardSettings' },
        href: userProfileEditPath,
        disabled: !userProfileEditPath,
      });
    }

    return {
      items,
      calloutProps: {
        styles: { root: { zIndex: 1000004 } },
        layerProps: { styles: { root: { zIndex: 1000005 } } },
      },
    };
  }, [t, onResetClick, onLinkClick, onEditClick, userProfileEditPath]);

  const rolesRenderer = useMemo(
    () =>
      (props: IPersonaProps | undefined): JSX.Element | null =>
        props ? <span style={{ maxWidth: 130, whiteSpace: 'break-spaces' }}>{props.secondaryText}</span> : null,
    []
  );

  return (
    <div className={styles.wrapper}>
      <h3 style={{ color: darkMode ? '#fefefe' : '#323130' }} className={styles.title}>
        {t('header.controls.profile.title')}
      </h3>
      <Persona {...profile} onRenderSecondaryText={rolesRenderer} />
      <br />
      <div className={styles.buttonWrapper}>
        <DefaultButton
          className={styles.editButton}
          iconProps={{ iconName: 'ContactCardSettings' }}
          text={t('header.controls.profile.edit')}
          split={true}
          splitButtonAriaLabel="See 2 options"
          aria-roledescription="split button"
          menuProps={editMenuProps}
          onClick={onEditClick}
          href={userProfileEditPath}
          checked={false}
          disabled={!userProfileEditPath}
        />
      </div>
    </div>
  );
};

export default ProfilePopup;
