import { EventEmitter } from 'events';

import {
  FieldTrigger,
  IJSONSchema,
  isDefined,
  OnAfterFormChangeExecutionContext,
  OnBeforeFormInitExecutionContext,
  OnInputBlurExecutionContext,
  Schemas,
  TypeTrigger,
} from '@cp/base-types';
import { clearNulls, cloneDeepWithMetadata, sleep } from '@cp/base-utils';
import { FormContext, ItemInfoContext } from '@cpa/base-core/constants';
import {
  cloneValueAndCleanUpInternalProperties,
  executeUiTriggers,
  ignoreRefs,
  isTouchDevice,
  removeEmptyObjects,
  validateFormData,
} from '@cpa/base-core/helpers';
import { usePowerUser } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import { DefaultButton, IContextualMenuProps, MessageBarType, PrimaryButton, Sticky, StickyPositionType } from '@fluentui/react';
import { useBoolean, useForceUpdate, usePrevious, useSetTimeout } from '@fluentui/react-hooks';
import { AjvError, ISubmitEvent, UiSchema } from '@rjsf/core';
import type { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { lazy, Suspense, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoadingArea from '../LoadingArea/LoadingArea';
import MessageBars from '../MessageBars/MessageBars';
import { IWizardOptions } from '../ScrollingContent/ScrollingContent';

import JsonSchemaForm, { FormRef } from './components/JsonSchemaForm/JsonSchemaForm';
import styles from './Form.module.scss';
import { compareSchemaSortOrder, makeUiSchema } from './helpers/data';
import theme from './helpers/theme';
import { IBlurOptions } from './hocs/withBlurEventHandling';

const JsonFormEditor = lazy(() => import('./components/JsonFormEditor/JsonFormEditor'));

export interface IFormChangeEvent {
  formData: any;
  errors?: AjvError[];
}

export interface IFormRef {
  submit(): void;
}

const FIELDS_ON_STEP = 2;

const isFieldRelatedToStep = (type?: 'add' | 'edit', hiddenInForm?: boolean, hiddenIfCreate?: boolean, hiddenIfUpdate?: boolean) => {
  if (type === 'add' && hiddenIfCreate) return false;
  if (type === 'edit' && hiddenIfUpdate) return false;
  return !hiddenInForm;
};

export interface IFormProps {
  schema: IJSONSchema;
  onBeforeSubmit?: (obj: { formData: unknown }) => Promise<{ cancelSubmit: boolean }>;
  onSubmit?: (obj: { formData: unknown }, closeDrawer?: boolean) => void | Promise<void>;
  onSubmitAs?: (obj: { formData: unknown }) => void | Promise<void>;
  closeForm?: (options?: { skipConfirmation?: boolean }) => Promise<void>;
  btnText?: string;
  formData?: IDataItem;
  readonly?: boolean;
  editFormsAsJson?: boolean;
  onChange?: (obj: IFormChangeEvent) => void;
  getCurrentFormState?: () => IDataItem | null | undefined;
  showSaveUrl?: boolean;
  onCopiedToClipboard?: (formDataToShare: IDataItem<unknown>) => void;
  onFieldLocalizationChanged?: (_eTag: string, language: string, dataPath: string, updatedValue: IDataItem) => void;
  hideSubmit?: boolean;
  page?: Schemas.CpaPage;
  disableStickySubmitButton?: boolean;
  enableKeyboardHotkeys?: boolean;
  wizardOptions?: IWizardOptions;
}

enum SubmitType {
  AsCopy = 'SUBMIT_AS_COPY',
  WithoutClose = 'SUBMIT_WITHOUT_CLOSE',
}

const loadingPlaceholder = <LoadingArea />;

const Form: React.FC<IFormProps> = ({
  schema,
  onBeforeSubmit,
  onSubmit,
  onSubmitAs,
  closeForm,
  btnText,
  formData,
  readonly = false,
  onChange,
  getCurrentFormState,
  showSaveUrl,
  onCopiedToClipboard,
  onFieldLocalizationChanged,
  editFormsAsJson,
  hideSubmit,
  page,
  disableStickySubmitButton,
  enableKeyboardHotkeys,
  wizardOptions,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const currentFormData = useRef(formData ? clearNulls(cloneDeepWithMetadata(formData)) : undefined);
  const timeoutManager = useSetTimeout();

  const formRef: FormRef = useRef(null);

  const previousCurrentFormData = useRef(currentFormData.current);
  const previousFormData = usePrevious(formData);

  if (previousFormData !== formData) {
    currentFormData.current = formData ? clearNulls(cloneDeepWithMetadata(formData)) : undefined;
  }

  const forceUpdate = useForceUpdate();
  const processedSchema: IJSONSchema = useMemo<IJSONSchema>(() => ignoreRefs<IJSONSchema>(schema), [schema]);
  const [formSchema, setFormSchema] = useState<IJSONSchema | null>(null);
  const [steppedSchema, setSteppedSchema] = useState<IJSONSchema | null>(null);
  const [t, i18n] = useTranslation();
  const id = useMemo(() => Math.random().toString(), []);
  const [step, setStep] = useState(0);
  const [visibleFields, setVisibleFields] = useState<string[]>([]);
  const [stepErrors, setStepErrors] = useState<AjvError[] | null>(null);

  const [fieldsCount, setFieldsCount] = useState<number>(0);

  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  const powerUser = usePowerUser();

  const currentLanguage = useMemo(() => {
    let currentLanguageCode = i18n.language;
    if (dataLanguage && dataLanguage !== 'notSelected') {
      currentLanguageCode = dataLanguage;
    }

    return currentLanguageCode;
  }, [dataLanguage, i18n.language]);

  const itemInfoContext = useContext(ItemInfoContext);

  useEffect(() => {
    const initTriggers = schema.cp_typeTriggers?.[TypeTrigger.OnBeforeFormInit];

    if (Array.isArray(initTriggers) && initTriggers.length) {
      const schemaForTrigger = cloneDeepWithMetadata(processedSchema);

      executeUiTriggers<OnBeforeFormInitExecutionContext>(
        {
          event: TypeTrigger.OnBeforeFormInit,
          data: currentFormData.current,
          schema: schemaForTrigger,
          page: page,
          showMessage: itemInfoContext?.showMessage,
          isCreate: itemInfoContext?.type === 'add',
          isUpdate: itemInfoContext?.type === 'edit',
          get formData(): IDataItem | undefined {
            return cloneDeepWithMetadata(currentFormData.current);
          },
          setFormData: (newFormData: IDataItem) => {
            currentFormData.current = newFormData;
            forceUpdate();
          },
          overrideFormSchema,
          closeForm,
        },
        initTriggers
      ).finally(() => {
        setFormSchema(schemaForTrigger);
      });
    } else {
      setFormSchema(processedSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  useEffect(() => {
    // Calculate number of fields
    if (!formSchema || !formSchema.properties) return;
    const fieldsCount = Object.keys(formSchema.properties).reduce((acc, property) => {
      const isHiddenInForm = formSchema.properties?.[property]?.cp_ui?.hiddenInForm;
      const isHiddenIfCreate = formSchema.properties?.[property]?.cp_ui?.hiddenIfCreate;
      const isHiddenIfUpdate = formSchema.properties?.[property]?.cp_ui?.hiddenIfUpdate;
      const isRequired = formSchema.required?.includes(property);
      if (wizardOptions?.requiredOnly && !isRequired) return acc;
      if (wizardOptions?.fields && !wizardOptions.fields.includes(property)) return acc;
      if (isFieldRelatedToStep(itemInfoContext?.type, isHiddenInForm, isHiddenIfCreate, isHiddenIfUpdate)) {
        acc++;
      }
      return acc;
    }, 0);
    setFieldsCount(fieldsCount);
  }, [formSchema, itemInfoContext?.type, wizardOptions?.fields, wizardOptions?.requiredOnly]);

  const stepsManagedBySchema = useMemo(() => {
    if (!schema.properties) return false;
    const isStepsManagedBySchema = Object.keys(schema.properties).some((key) => {
      return schema.properties?.[key].cp_ui?.wizardGroup;
    });
    if (!isStepsManagedBySchema) return null;
    const managedSteps = Object.keys(schema.properties).reduce((acc, key) => {
      const wizardGroup = schema.properties?.[key].cp_ui?.wizardGroup;
      if (isDefined(wizardGroup)) {
        acc[wizardGroup] = acc[wizardGroup] ? [...acc[wizardGroup], key] : [key];
      }
      return acc;
    }, [] as string[][]);
    // remove empty values from array
    const stepsWithoutEmptyValues = Array.from(managedSteps, (item) => item || []);
    const notManagedSteps = Object.keys(schema.properties).reduce((acc, key) => {
      const wizardGroup = schema.properties?.[key].cp_ui?.wizardGroup;
      if (!isDefined(wizardGroup)) {
        acc = [...acc, key];
      }
      return acc;
    }, [] as string[]);
    if (notManagedSteps.length) {
      return [...stepsWithoutEmptyValues, notManagedSteps];
    }
    return stepsWithoutEmptyValues;
  }, [schema]);

  const correctedSteps = useMemo(() => {
    const steps = wizardOptions?.steps;
    if (!steps) return steps;
    const totalInputSteps = steps.reduce((acc, c) => (acc += c), 0);
    if (totalInputSteps < fieldsCount) {
      return [...steps, fieldsCount - totalInputSteps];
    }
    if (totalInputSteps > fieldsCount) {
      let stepsDifference = totalInputSteps - fieldsCount;
      return _.clone(steps)
        .reverse()
        .map((c) => {
          if (c === 0 || stepsDifference === 0) {
            return c;
          }
          if (c >= totalInputSteps) {
            const updatedValue = c - stepsDifference;
            stepsDifference = 0;
            return updatedValue;
          } else {
            stepsDifference = stepsDifference - c;
            return 0;
          }
        })
        .filter(Boolean)
        .reverse();
    }
    return steps;
  }, [fieldsCount, wizardOptions?.steps]);

  const stepsCount = useMemo(() => {
    if (stepsManagedBySchema && schema.properties) {
      return stepsManagedBySchema.length;
    }
    return correctedSteps ? correctedSteps.length : Math.ceil(fieldsCount / FIELDS_ON_STEP);
  }, [correctedSteps, fieldsCount, stepsManagedBySchema, schema.properties]);

  const completedFieldsCount = useMemo(() => {
    if (stepsManagedBySchema && schema.properties) {
      return stepsManagedBySchema.reduce((acc, group, index) => {
        if (index < step) {
          acc += group.length;
        }
        return acc;
      }, 0);
    }
    if (!correctedSteps) {
      return FIELDS_ON_STEP * (step || 1);
    }
    return (correctedSteps as number[]).reduce((acc, c, index) => {
      if (index < step) {
        acc += c;
        return acc;
      }
      return acc;
    }, 0);
  }, [correctedSteps, stepsManagedBySchema, schema.properties, step]);

  const progress = useMemo(() => {
    if (stepsManagedBySchema) {
      return ((completedFieldsCount + visibleFields.length) / fieldsCount) * 100;
    }
    if (!correctedSteps) {
      return ((FIELDS_ON_STEP * step + visibleFields.length) / fieldsCount) * 100;
    }
    const fieldsOnCurrentStep: number = correctedSteps[step];
    return ((completedFieldsCount + fieldsOnCurrentStep) / fieldsCount) * 100;
  }, [completedFieldsCount, correctedSteps, fieldsCount, step, stepsManagedBySchema, visibleFields.length]);

  const handleStepBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleStepNext = useCallback(() => {
    // Add validation of current step formData
    const formDataClone: IDataItem<unknown> | undefined = _.cloneDeep(currentFormData.current);
    if (!formDataClone) return;
    for (const property of Object.keys(formDataClone)) {
      if (visibleFields.includes(property)) continue;
      delete formDataClone[property];
    }
    const formDataArrayKeys = Object.keys(formDataClone).filter((key) => {
      return !!Array.isArray(formDataClone[key]);
    });
    const clearedFormData = removeEmptyObjects(formDataClone) as Record<string, unknown>;
    for (const arrayKey of formDataArrayKeys) {
      if (clearedFormData[arrayKey]) {
        clearedFormData[arrayKey] = formDataClone[arrayKey];
      }
    }
    const schemaClone = _.cloneDeep(formSchema);
    if (!schemaClone || !schemaClone.properties) return;
    for (const property of Object.keys(schemaClone.properties)) {
      if (visibleFields.includes(property)) continue;
      delete schemaClone.properties[property];
    }
    if (schemaClone?.cp_validationSchema) {
      if (schemaClone?.cp_validationSchema.properties) {
        for (const property of Object.keys(schemaClone.cp_validationSchema.properties)) {
          if (visibleFields.includes(property)) continue;
          delete schemaClone.cp_validationSchema.properties[property];
        }
      }
      if (schemaClone?.cp_validationSchema.required) {
        schemaClone.cp_validationSchema.required = schemaClone.cp_validationSchema.required.filter((path) => visibleFields.includes(path));
      }
    }
    if (schemaClone.required) {
      schemaClone.required = schemaClone.required.filter((path) => visibleFields.includes(path));
    }
    const errors = validateFormData(clearedFormData as IDataItem<unknown>, schemaClone);
    setStepErrors(null);
    if (errors.errors.length) {
      setStepErrors(errors.errors);
      return;
    }
    setStep(step + 1);
  }, [formSchema, step, visibleFields]);

  const getFieldOnCurrentStep = useCallback(() => {
    if (correctedSteps) {
      return correctedSteps[step] ?? fieldsCount - completedFieldsCount;
    }
    return FIELDS_ON_STEP;
  }, [completedFieldsCount, correctedSteps, fieldsCount, step]);

  const showNextManagedSteps = useCallback(() => {
    const shownKeys: string[] = [];
    const schemaCopy = cloneDeepWithMetadata(formSchema);
    if (!schemaCopy || !schemaCopy.properties || !stepsManagedBySchema) return;
    const currentFields = stepsManagedBySchema[step];
    const properties = Object.keys(schemaCopy.properties);
    for (const property of properties) {
      const schemaProperty = schemaCopy.properties[property];
      if (!schemaProperty.cp_ui) {
        schemaProperty.cp_ui = {};
      }
      const uiProperties = schemaCopy.properties[property].cp_ui || {};
      if (currentFields.includes(property)) {
        if (!isFieldRelatedToStep(itemInfoContext?.type, uiProperties.hiddenInForm, uiProperties.hiddenIfCreate, uiProperties.hiddenIfUpdate)) {
          continue;
        }
        uiProperties.hiddenInForm = false;
        shownKeys.push(property);
        if (currentFields.length === 1 && schemaProperty) {
          if (schemaProperty.cp_rjsfUiSchema) {
            schemaProperty.cp_rjsfUiSchema = {
              ...schemaProperty.cp_rjsfUiSchema,
              defaultExpanded: true,
            };
          } else {
            schemaProperty.cp_rjsfUiSchema = {
              defaultExpanded: true,
            };
          }
        }
      } else {
        uiProperties.hiddenInForm = true;
      }
    }
    setVisibleFields(shownKeys);
    setSteppedSchema(schemaCopy);
  }, [formSchema, itemInfoContext?.type, step, stepsManagedBySchema]);

  // Modify schema in effect to follow steps
  useEffect(() => {
    if (!wizardOptions?.isWizardMode) {
      setSteppedSchema(formSchema);
      return;
    }
    const schemaCopy = cloneDeepWithMetadata(formSchema);
    const fieldsOnCurrentStep = getFieldOnCurrentStep();
    if (stepsManagedBySchema) {
      showNextManagedSteps();
      return;
    }
    let skip = completedFieldsCount;
    let shown = 0;
    const shownKeys: string[] = [];
    if (!schemaCopy || !schemaCopy.properties) return;
    const properties = Object.entries(schemaCopy.properties)
      // By default, sort by schema order, if fields provided sort by fields order
      .sort((a, b) =>
        wizardOptions.fields ? wizardOptions.fields.indexOf(a[0]) - wizardOptions.fields.indexOf(b[0]) : compareSchemaSortOrder(a[1], b[1])
      )
      .map((prop) => prop[0]);
    if (!schemaCopy.properties) return;
    for (const property of properties) {
      const schemaProperty = schemaCopy.properties[property];
      if (!schemaProperty.cp_ui) {
        schemaProperty.cp_ui = {};
      }
      const uiProperties = schemaCopy.properties[property].cp_ui || {};
      if (
        (wizardOptions.requiredOnly && !schemaCopy.required?.includes(property)) ||
        (wizardOptions.fields && !wizardOptions.fields.includes(property))
      ) {
        uiProperties.hiddenInForm = true;
        continue;
      }
      if (skip > 0 && step !== 0) {
        if (!isFieldRelatedToStep(itemInfoContext?.type, uiProperties.hiddenInForm, uiProperties.hiddenIfCreate, uiProperties.hiddenIfUpdate)) {
          continue;
        }
        skip--;
        uiProperties.hiddenInForm = true;
        continue;
      }
      if (shown !== fieldsOnCurrentStep) {
        if (!isFieldRelatedToStep(itemInfoContext?.type, uiProperties.hiddenInForm, uiProperties.hiddenIfCreate, uiProperties.hiddenIfUpdate)) {
          continue;
        }
        uiProperties.hiddenInForm = false;
        shownKeys.push(property);
        shown++;
        if (fieldsOnCurrentStep === 1 && schemaProperty) {
          if (schemaProperty.cp_rjsfUiSchema) {
            schemaProperty.cp_rjsfUiSchema = {
              ...schemaProperty.cp_rjsfUiSchema,
              defaultExpanded: true,
            };
          } else {
            schemaProperty.cp_rjsfUiSchema = {
              defaultExpanded: true,
            };
          }
        }
        continue;
      }
      uiProperties.hiddenInForm = true;
    }
    setVisibleFields(shownKeys);
    setSteppedSchema(schemaCopy);
  }, [
    getFieldOnCurrentStep,
    stepsManagedBySchema,
    showNextManagedSteps,
    completedFieldsCount,
    correctedSteps,
    fieldsCount,
    formSchema,
    itemInfoContext?.type,
    step,
    wizardOptions?.isWizardMode,
    wizardOptions?.requiredOnly,
    wizardOptions?.fields,
  ]);

  const validationErrors = useMemo(() => {
    if (!stepErrors?.length) return null;
    return stepErrors.map((error) => error.stack);
  }, [stepErrors]);

  const generateFormEventEmitter = (): EventEmitter => {
    const eventEmitter = new EventEmitter();
    eventEmitter.setMaxListeners(0);
    return eventEmitter;
  };

  const formEventEmitter = useMemo(() => generateFormEventEmitter(), []);
  const [schemaOverwrites, setSchemaOverwrites] = useState<Record<string, IJSONSchema>>({});

  const overrideFormSchema = useCallback(
    (schemaOverrides: Record<string, IJSONSchema>): void => {
      setSchemaOverwrites((currentOverwrites) => {
        const newOverrides = _.cloneDeep(currentOverwrites);

        for (const [path, schema] of Object.entries(schemaOverrides)) {
          if (!schema || !Object.keys(schema).length) {
            delete newOverrides[path];
          } else {
            newOverrides[path] = schema;
          }
        }

        return newOverrides;
      });
    },
    [setSchemaOverwrites]
  );

  const submitDisableTimeout = useRef<number | null>(null);
  const readyToSubmit = useRef<{ ready: boolean; submitFunction?: () => void }>({ ready: false });
  const actionsCounter = useRef(0);
  const [submitDisabled, { setTrue: disableSubmit, setFalse: enableSubmit }] = useBoolean(false);

  const onChangeHandler = useCallback(
    (obj: IFormChangeEvent) => {
      currentFormData.current = obj.formData ? clearNulls(cloneDeepWithMetadata(obj.formData)) : undefined;

      onChange?.(obj);

      const changeTriggers = formSchema?.cp_typeTriggers?.[TypeTrigger.OnAfterFormChange];
      if (Array.isArray(changeTriggers) && changeTriggers.length && formSchema) {
        executeUiTriggers<OnAfterFormChangeExecutionContext>(
          {
            event: TypeTrigger.OnAfterFormChange,
            schema: formSchema,
            page: page,
            showMessage: itemInfoContext?.showMessage,
            dismissMessage: itemInfoContext?.dismissMessage,
            isCreate: itemInfoContext?.type === 'add',
            isUpdate: itemInfoContext?.type === 'edit',
            get formData(): IDataItem | undefined {
              return cloneDeepWithMetadata(currentFormData.current);
            },
            previousFormData: previousCurrentFormData.current,
            setFormData: (newFormData: IDataItem) => {
              currentFormData.current = newFormData;
              forceUpdate();
            },
            overrideFormSchema,
          },
          changeTriggers
        );
      }

      formEventEmitter.emit('change', currentFormData.current);

      previousCurrentFormData.current = currentFormData.current;
    },
    [forceUpdate, formEventEmitter, formSchema, itemInfoContext, onChange, page, overrideFormSchema]
  );

  const disableSubmitForLongActions = useCallback(() => {
    if (submitDisableTimeout.current === null) {
      submitDisableTimeout.current = timeoutManager.setTimeout(() => {
        submitDisableTimeout.current = null;
        if (readyToSubmit.current.ready) {
          console.warn(`Form is not ready to submit, disabling submit button. Background actions: ${actionsCounter.current}.`);
          disableSubmit();
        }
      }, 1000);
    }
  }, [disableSubmit, timeoutManager]);

  const onFormSubmitAs = useCallback(
    (formData: unknown) => {
      if (onSubmitAs && formData) {
        onSubmitAs({ formData });
      }
      enableSubmit();
    },
    [enableSubmit, onSubmitAs]
  );

  const onFormSubmit = useCallback(
    async (event: ISubmitEvent<{}> | null, formEvent: React.FormEvent<HTMLFormElement>) => {
      // Trigger image upload/conversion from base64 to cdn url in TinyMCE instances
      if (editorInstances.current) {
        for (const editorInstance of editorInstances.current) {
          await editorInstance.editor?.uploadImages();
        }
      }

      // We need to let react update in order to receive change event from rjsf
      // https://github.com/rjsf-team/react-jsonschema-form/blob/c78a1c9de2fb1913fd1052fc9e0e879a76aa8225/packages/core/src/components/Form.tsx#L594
      await sleep(0);

      const type = (formEvent.nativeEvent as Event & { detail?: string }).detail;
      const formData = currentFormData.current;

      if (!formData) return;

      switch (type) {
        case SubmitType.AsCopy: {
          onFormSubmitAs(formData);
          break;
        }
        case SubmitType.WithoutClose: {
          onSubmit?.({ formData: formData }, false);
          break;
        }
        default: {
          onSubmit?.({ formData: formData });
        }
      }

      enableSubmit();
    },
    [enableSubmit, onFormSubmitAs, onSubmit]
  );

  const initiateFormSubmit = useCallback(
    async (event?: unknown, type?: SubmitType) => {
      (event as SyntheticEvent)?.preventDefault?.();
      (event as SyntheticEvent)?.stopPropagation?.();

      console.info(`Form submit initiated. Background actions: ${actionsCounter.current}.`);
      if (actionsCounter.current > 0) {
        readyToSubmit.current = { ready: true, submitFunction: initiateFormSubmit };

        // Disable submit if trigger takes too much time
        disableSubmitForLongActions();
        return;
      }

      if (editFormsAsJson) {
        await onFormSubmit(null, event as React.FormEvent<HTMLFormElement>);
      } else {
        const cancelSubmit = (await onBeforeSubmit?.({ formData: currentFormData.current }))?.cancelSubmit ?? false;
        if (!cancelSubmit) {
          // https://github.com/rjsf-team/react-jsonschema-form/issues/2104#issuecomment-848399222
          (formRef?.current as any)?.formElement.dispatchEvent(
            new CustomEvent('submit', {
              detail: type,
              cancelable: true,
              bubbles: true,
            })
          );
        }
      }
    },
    [disableSubmitForLongActions, onBeforeSubmit, onFormSubmit, editFormsAsJson]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (enableKeyboardHotkeys && (event.ctrlKey || event.metaKey) && event.code === 'KeyS') {
        initiateFormSubmit(undefined, SubmitType.WithoutClose);
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [enableKeyboardHotkeys, initiateFormSubmit]);

  const startAction = useCallback(() => {
    actionsCounter.current++;
    console.info(`Started form background action. Current count: ${actionsCounter.current}.`);
  }, []);
  const finishAction = useCallback(async () => {
    actionsCounter.current--;
    console.info(`Finished form background action. Current count: ${actionsCounter.current}.`);

    if (actionsCounter.current === 0) {
      if (readyToSubmit.current.ready) {
        // Ready to submit
        readyToSubmit.current.ready = false;
        // Submit
        console.info(`Form background actions are finished, initiating form submit...`);

        // We need this in order to skip one tick and allow change events to be handled
        // It fixed file upload + submit
        await sleep(0);
        readyToSubmit.current.submitFunction?.();
        readyToSubmit.current.submitFunction = undefined;
      }
    }
  }, []);

  const editorInstances = useRef(new Set<Editor>());

  const registerEditor = useCallback(
    (editor: Editor) => {
      if (editorInstances.current) {
        editorInstances.current.add(editor);
      }
    },
    [editorInstances]
  );

  const unregisterEditor = useCallback(
    (editor: Editor) => {
      if (editorInstances.current) {
        editorInstances.current.delete(editor);
      }
    },
    [editorInstances]
  );

  const handleFieldLocalizationChanged = useCallback(
    (_eTag: string, language: string, dataPath: string, updatedValue: IDataItem) => {
      onFieldLocalizationChanged?.(_eTag, language, dataPath, updatedValue);

      const updatedFormData = cloneDeepWithMetadata({ ...currentFormData.current, _eTag: _eTag });

      if (currentLanguage === language) {
        const path = _.toPath(dataPath);
        const fieldName = path.slice(-1)[0];

        _.set(updatedFormData, path, updatedValue[fieldName]);
      }

      onChangeHandler({
        formData: updatedFormData,
      });
    },
    [onFieldLocalizationChanged, onChangeHandler, currentLanguage]
  );

  const sharePayload = useCallback(async () => {
    const formDataToShare = cloneValueAndCleanUpInternalProperties(getCurrentFormState?.() || {}, schema);
    delete formDataToShare['identifier'];

    onCopiedToClipboard?.(formDataToShare);
  }, [onCopiedToClipboard, getCurrentFormState, schema]);

  const menuProps: IContextualMenuProps = useMemo(() => {
    const touchDevice: boolean = isTouchDevice();
    const items: IContextualMenuProps['items'] = [];

    if (itemInfoContext?.type === 'edit') {
      items.push({
        key: 'saveAsCopy',
        text: t('common.submitAsCopy'),
        onClick: (): void => {
          initiateFormSubmit(undefined, SubmitType.AsCopy);
        },
      });
    }

    items.push({
      key: 'saveWithoutClose',
      text: t('common.submitWithoutClose'),
      onClick: (): void => {
        initiateFormSubmit(undefined, SubmitType.WithoutClose);
      },
    });

    if (onCopiedToClipboard) {
      items.push({
        key: 'share',
        text: t('common.share'),
        onClick: (): void => {
          sharePayload();
        },
      });
    }

    if (touchDevice) {
      items.unshift({
        key: 'submit',
        text: btnText || t('common.submit'),
        onClick: (): void => {
          initiateFormSubmit();
        },
      });
    }

    return { items };
  }, [itemInfoContext?.type, t, onCopiedToClipboard, sharePayload, btnText, initiateFormSubmit]);

  const submitButtonStyle = useMemo(() => ({ display: hideSubmit ? 'none' : undefined }), [hideSubmit]);

  const submitButton = useMemo(() => {
    return showSaveUrl && powerUser ? (
      <PrimaryButton
        data-testid="Form__submitButton"
        form={id}
        type="submit"
        split={true}
        style={submitButtonStyle}
        className={styles.btnSplit}
        as="label"
        htmlFor={id}
        menuProps={menuProps}
        onClick={initiateFormSubmit}
        text={btnText || t('common.submit')}
        disabled={readonly || !onSubmit || submitDisabled}
      />
    ) : (
      <PrimaryButton
        data-testid="Form__submitButton"
        form={id}
        type="submit"
        style={submitButtonStyle}
        as="label"
        htmlFor={id}
        onClick={initiateFormSubmit}
        text={btnText || t('common.submit')}
        disabled={readonly || !onSubmit || submitDisabled}
      />
    );
  }, [powerUser, showSaveUrl, id, submitButtonStyle, menuProps, initiateFormSubmit, btnText, t, readonly, onSubmit, submitDisabled]);

  const uiSchema: UiSchema | undefined = useMemo(() => {
    if (!steppedSchema) {
      return;
    }
    const uiSchema = makeUiSchema(steppedSchema);
    if (_.isEmpty(uiSchema)) {
      return;
    }

    return uiSchema;
  }, [steppedSchema]);

  const onFormBlur = useCallback(
    async (fieldId: string, value: unknown, customOptions: IBlurOptions = {}) => {
      if (!formSchema) {
        return;
      }

      // getting array of path names
      let fieldNamePath = (fieldId.startsWith('root_') ? fieldId.slice(5) : fieldId).split('_');

      // checking if it is relation field
      if (fieldNamePath.length > 1 && fieldNamePath.lastIndexOf('identifier') === fieldNamePath.length - 1) {
        fieldNamePath = fieldNamePath.slice(0, fieldNamePath.length - 1);
      }

      if (!customOptions.schema) {
        console.debug(`No subschema found for id: ${fieldId}`, formSchema);
        return;
      }

      // Get triggers from current schema
      const triggers: string[] = [];
      const fieldTriggers: string[] | undefined = customOptions.schema?.cp_fieldTriggers?.[FieldTrigger.OnInputBlur];
      if (fieldTriggers) {
        triggers.push(...fieldTriggers);
      }

      // If field is relation (field path ends with 'identifier') also check upper level schema for triggers
      if (customOptions.path?.endsWith(`['identifier']`)) {
        // Get schema from upper level
        let parentSchema: IJSONSchema | undefined = formSchema;
        for (const fieldNamePathPart of fieldNamePath) {
          parentSchema = parentSchema?.properties?.[fieldNamePathPart];
        }
        // If parent schema found add parent schema triggers
        if (parentSchema) {
          const parentTriggers: string[] | undefined = parentSchema?.cp_fieldTriggers?.[FieldTrigger.OnInputBlur];
          if (parentTriggers) {
            triggers.push(...parentTriggers);
          }
        }
      }

      // Execute 'OnInputBlur' triggers
      if (Array.isArray(triggers) && triggers.length > 0) {
        startAction();
        try {
          await executeUiTriggers<OnInputBlurExecutionContext>(
            {
              event: FieldTrigger.OnInputBlur,
              showMessage: itemInfoContext?.showMessage,
              dismissMessage: itemInfoContext?.dismissMessage,
              fieldValue: value,
              fieldItem: customOptions.fieldItem,
              fieldSchema: customOptions.schema,
              fieldPath: customOptions.path,
              fieldId: fieldId,
              fieldName: fieldNamePath[fieldNamePath.length - 1],
              schema: formSchema,
              page: page,
              initialFormData: formData,
              rootFormData: currentFormData.current,
              oldFieldValue: customOptions.oldFieldValue,
              isCreate: itemInfoContext?.type === 'add',
              isUpdate: itemInfoContext?.type === 'edit',
              get formData(): IDataItem | undefined {
                return cloneDeepWithMetadata(currentFormData.current);
              },
              setFormData: (newFormData: IDataItem) => {
                currentFormData.current = newFormData;
                forceUpdate();
              },
              overrideFormSchema,
            },
            triggers
          );
        } finally {
          finishAction();
        }
      }
    },
    [
      formSchema,
      startAction,
      itemInfoContext?.showMessage,
      itemInfoContext?.dismissMessage,
      itemInfoContext?.type,
      page,
      formData,
      finishAction,
      forceUpdate,
      overrideFormSchema,
    ]
  );

  const formContextValue = useMemo(
    () => ({
      startAction: startAction,
      finishAction: finishAction,
      registerEditor: registerEditor,
      unregisterEditor: unregisterEditor,
      onFieldLocalizationChanged: handleFieldLocalizationChanged,
      page,
      formEventEmitter,
      schemaOverwrites,
      currentFormData,
      initialFormData: formData,
    }),
    [finishAction, startAction, registerEditor, unregisterEditor, page, formEventEmitter, schemaOverwrites, handleFieldLocalizationChanged, formData]
  );

  if (!steppedSchema) {
    return <LoadingArea />;
  }

  return (
    <ErrorBoundary>
      <FormContext.Provider value={formContextValue}>
        <div className={classNames(styles.form, { [styles.formWithJson]: editFormsAsJson })}>
          {editFormsAsJson && (
            <Suspense fallback={loadingPlaceholder}>
              <JsonFormEditor formData={currentFormData.current} onChange={onChangeHandler}>
                {submitButton}
              </JsonFormEditor>
            </Suspense>
          )}
          {wizardOptions?.isWizardMode ? (
            <Sticky stickyPosition={StickyPositionType.Header}>
              <MessageBars messages={validationErrors || []} messageBarType={MessageBarType.error}></MessageBars>
              <div className={styles.progressWrapper}>
                <div>{t('common.stepN', { step: step + 1, stepsCount: stepsCount })}</div>
                <div className={styles.progress}>
                  <div style={{ width: `${progress}%` }}></div>
                </div>
              </div>
            </Sticky>
          ) : null}
          <div className={classNames({ [styles.formEditorHidden]: editFormsAsJson })}>
            <JsonSchemaForm
              formRef={formRef}
              id={id}
              schema={steppedSchema as IJSONSchema}
              uiSchema={uiSchema}
              onChange={onChangeHandler}
              onBlur={onFormBlur}
              onSubmit={(e, formEvent) => onFormSubmit(e, formEvent)}
              formData={currentFormData.current}
              noHtml5Validate={true}
              {...theme}
            >
              {disableStickySubmitButton ? (
                <div className={classNames(styles.buttonContainer, { [styles.dark]: darkMode })}>{submitButton}</div>
              ) : (
                <Sticky stickyPosition={StickyPositionType.Footer} stickyBackgroundColor="transparent">
                  <div
                    data-testid="Form__buttonsWrapper"
                    className={classNames(styles.buttonsWrapper, { [styles.submitOnly]: !wizardOptions?.isWizardMode })}
                  >
                    {wizardOptions?.isWizardMode ? (
                      <div className={styles.navigationButtons}>
                        <DefaultButton disabled={step === 0} onClick={handleStepBack}>
                          {t('common.previous')}
                        </DefaultButton>
                        <DefaultButton disabled={step + 1 === stepsCount} onClick={handleStepNext}>
                          {t('common.next')}
                        </DefaultButton>
                      </div>
                    ) : null}
                    {wizardOptions?.isWizardMode && step + 1 !== stepsCount ? null : (
                      <div data-testid="Form__buttonContainer" className={classNames(styles.buttonContainer, { [styles.dark]: darkMode })}>
                        {submitButton}
                      </div>
                    )}
                  </div>
                </Sticky>
              )}
            </JsonSchemaForm>
          </div>
        </div>
      </FormContext.Provider>
    </ErrorBoundary>
  );
};

export default Form;
