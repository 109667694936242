import {
  IJSONSchema,
  OnAfterUiItemReadExecutionContext,
  OnBeforeUiCreateExecutionContext,
  OnBeforeUiDeleteExecutionContext,
  OnBeforeUiUpdateExecutionContext,
  TypeTrigger,
} from '@cp/base-types';
import {
  DataServiceModules,
  IFacetFilterField,
  IFacetFilterFieldData,
  buildODataQuery,
  cloneDeepWithMetadata,
  escapeIllegalOdataValueChars,
} from '@cp/base-utils';
import { CancelTokenSource, Method } from 'axios';
import hash from 'object-hash';
import urlJoin from 'url-join';

import { accountPlaceholderPattern } from '../constants';
import { cleanupRecursive, clearVirtualProperties, executeUiTriggers, perAccount, prepareActionLink } from '../helpers';
import { getAccountNo } from '../hooks/account';
import { IGlobalState, store } from '../store';
import { BaseApi, IDataItem, IEntitiesResponse } from '../types';

import { getEndpoint } from './endpoint';
import { getSchema } from './schemas';

export interface IDataStoreArrayDto<T = IDataItem> {
  value: Array<T>;
  $schema?: string;
  totalItems: number;
}

export interface IDataStoreItemDto<T = IDataItem> {
  value: T;
  $schema: string;
}

function getCpDataCompany(): string | null {
  const companies = store.getState().auth.user?.companies;

  if (!companies || companies.length === 0) {
    return null;
  }

  // TODO: handle many companies and many roles cases
  return companies[0].company;
}

export const ensureItemIds = (items: IDataItem[]): IDataItem[] => {
  if (!Array.isArray(items)) {
    return items;
  }

  return items.map((item) => {
    if (item && !item.identifier) {
      const copy = Object.assign({}, item);
      Object.defineProperty(copy, '__identifier', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: hash(item),
      });
      return copy;
    } else {
      return item;
    }
  });
};

export async function executeFacetFilters(
  endpointId: string,
  subjectUri: string,
  variables: Record<string, unknown> = {},
  cancelToken?: CancelTokenSource,
  throwOnCancel?: boolean
): Promise<{ fields: IFacetFilterField[]; facetFilters: IFacetFilterFieldData[]; affectedSubjectUris: string[] }> {
  const endpoint = getEndpoint(endpointId);
  const axios = throwOnCancel ? endpoint.axiosWithoutCancelInterceptor : endpoint.axios;

  // DataService behaviour - single request with odata
  if (endpoint.dataType === BaseApi.DataService) {
    return await axios.request({
      url: `/${DataServiceModules.DATA_STORE}/${encodeURIComponent(subjectUri)}/facet-filters/execute`,
      cancelToken: cancelToken?.token,
      method: 'POST',
      data: variables,
    });
  }

  throw new Error(`Unknown endpoint data type ${endpoint.dataType}`);
}

export async function executeAggregationTemplate<T extends IDataItem = IDataItem>(
  endpointId: string,
  subjectUri: string,
  identifier: string,
  variables: Record<string, unknown> = {},
  affectedSubjectUris: string[] = [],
  cancelToken?: CancelTokenSource,
  throwOnCancel?: boolean,
  odataShouldBeAfterwards?: boolean
): Promise<T[]> {
  const endpoint = getEndpoint(endpointId);
  const axios = throwOnCancel ? endpoint.axiosWithoutCancelInterceptor : endpoint.axios;

  // DataService behaviour - single request with odata
  if (endpoint.dataType === BaseApi.DataService) {
    let url = `/${DataServiceModules.DATA_STORE}/${encodeURIComponent(subjectUri)}/aggregation-templates/${encodeURIComponent(identifier)}/execute`;

    if (affectedSubjectUris.length) {
      url += `?affectedSubjectUris=${encodeURIComponent(Array.from(affectedSubjectUris).join(','))}`;
    }

    if (odataShouldBeAfterwards) {
      url += url.includes('?') ? '&' : '?';
      url += `odataAfterwards=true`;
    }

    const { value } = await axios.request<IDataStoreArrayDto<T>>({
      url: url,
      cancelToken: cancelToken?.token,
      method: 'POST',
      data: variables,
    });

    return value;
  }

  throw new Error(`Unknown endpoint data type ${endpoint.dataType}`);
}

export async function executePromptTemplate(
  endpointId: string,
  subjectUri: string,
  identifier: string,
  variables: Record<string, unknown> = {},
  cancelToken?: CancelTokenSource,
  throwOnCancel?: boolean
): Promise<string> {
  const endpoint = getEndpoint(endpointId);
  const axios = throwOnCancel ? endpoint.axiosWithoutCancelInterceptor : endpoint.axios;

  // DataService behaviour - single request with odata
  if (endpoint.dataType === BaseApi.DataService) {
    const url = `/${DataServiceModules.DATA_STORE}/${encodeURIComponent(subjectUri)}/llm-template/${encodeURIComponent(identifier)}/execute`;

    const value = await axios.request<string>({
      url: url,
      cancelToken: cancelToken?.token,
      method: 'POST',
      data: variables,
    });

    return value;
  }

  throw new Error(`Unknown endpoint data type ${endpoint.dataType}`);
}

/*
  Get Entities generic function
 */
export function getEntitiesFromEndpoint(
  endpointId: string,
  path: string,
  queryOptions?: Record<string, unknown>,
  disableTriggers?: boolean,
  cancelToken?: CancelTokenSource,
  method: Method = 'GET',
  body?: unknown,
  throwOnCancel?: boolean
): Array<Promise<IEntitiesResponse>> {
  const endpoint = getEndpoint(endpointId);
  const axios = throwOnCancel ? endpoint.axiosWithoutCancelInterceptor : endpoint.axios;
  let promisesArray: Array<Promise<IEntitiesResponse>> | undefined = undefined;

  // Replace placeholders if present
  path = replaceDataUrlPlaceholders(path);

  // ApiGateway behaviour - Req per account
  if (endpoint.dataType === BaseApi.ApiGateway) {
    let firstSchemaUrl: string | null = null;
    let schemaPromise: Promise<IJSONSchema> | null = null;

    // Handle api request with authenticated user
    const userAuth = store.getState().auth.user;
    if (userAuth && path.match(accountPlaceholderPattern)) {
      // Different accounts may fetch same item twice, we need fetchedIdentifiers to avoid such duplicates
      // It is relevant for example for Contact Persons in MyCOSMO
      const fetchedIdentifiers = new Set<string>();

      promisesArray = perAccount(async (company, accountNo) => {
        const response = await axios.get<IDataStoreArrayDto>(path.replace(accountPlaceholderPattern, accountNo), {
          paramsSerializer: buildODataQuery,
          params: queryOptions,
          headers: {
            'x-cp-data-company': company,
          },
          cancelToken: cancelToken?.token,
        });

        const uniqueItems = (Array.isArray(response.value) ? response.value : [response.value]).filter((item) => {
          if (!item || !item.identifier) {
            return true;
          }

          if (fetchedIdentifiers.has(item.identifier)) {
            return false;
          }

          fetchedIdentifiers.add(item.identifier);
          return true;
        });

        if (!schemaPromise && !response.$schema) {
          schemaPromise = Promise.resolve({});
        }
        if (!schemaPromise && response.$schema) {
          schemaPromise = getSchema(response.$schema);
        }
        if (!firstSchemaUrl && response.$schema) {
          firstSchemaUrl = response.$schema;
        }

        const schema = await schemaPromise;
        if (!schema) {
          throw new Error('Failed to fetch schema for endpoint ');
        }

        if (firstSchemaUrl && firstSchemaUrl !== response.$schema) {
          console.warn(`Different schemas for same data endpoint detected. ${firstSchemaUrl} and`, response.$schema);
          return {
            entities: [],
            schema,
            totalItems: response.totalItems,
          };
        }

        for (const entity of uniqueItems) {
          Object.defineProperty(entity, '__company', {
            enumerable: false,
            configurable: false,
            writable: false,
            value: company,
          });
          Object.defineProperty(entity, '__accountNo', {
            enumerable: false,
            configurable: false,
            writable: false,
            value: accountNo,
          });
        }

        const triggers = schema.cp_typeTriggers?.[TypeTrigger.OnAfterUiItemRead];
        if (!disableTriggers && Array.isArray(triggers) && triggers.length && uniqueItems.length) {
          for (const item of uniqueItems) {
            await executeUiTriggers<OnAfterUiItemReadExecutionContext>(
              {
                event: TypeTrigger.OnAfterUiItemRead,
                item: item,
              },
              triggers
            );
          }
        }

        return {
          entities: ensureItemIds(uniqueItems),
          schema,
          totalItems: response.totalItems,
        };
      });
    } else {
      // Handle case of anonymous user or no accountNo in path
      promisesArray = [
        (async (): Promise<IEntitiesResponse> => {
          const response = await axios.request<IDataStoreArrayDto>({
            url: path,
            paramsSerializer: buildODataQuery,
            params: queryOptions,
            cancelToken: cancelToken?.token,
            method: method,
            data: body,
          });
          if (!schemaPromise && !response.$schema) {
            schemaPromise = Promise.resolve({});
          }
          if (!schemaPromise && response.$schema) {
            schemaPromise = getSchema(response.$schema);
          }
          if (!firstSchemaUrl && response.$schema) {
            firstSchemaUrl = response.$schema;
          }

          const schema = await schemaPromise;
          if (!schema) {
            throw new Error('Failed to fetch schema for endpoint ');
          }

          if (firstSchemaUrl && firstSchemaUrl !== response.$schema) {
            console.warn(`Different schemas for same data endpoint detected. ${firstSchemaUrl} and`, response.$schema);
            return {
              entities: [],
              schema,
              totalItems: response.totalItems,
            };
          }

          const triggers = schema.cp_typeTriggers?.[TypeTrigger.OnAfterUiItemRead];
          if (!disableTriggers && Array.isArray(triggers) && triggers.length && Array.isArray(response.value) && response.value.length) {
            for (const item of response.value) {
              await executeUiTriggers<OnAfterUiItemReadExecutionContext>(
                {
                  event: TypeTrigger.OnAfterUiItemRead,
                  item: item,
                },
                triggers
              );
            }
          }

          return {
            entities: ensureItemIds(response.value as IDataItem[]),
            schema,
            totalItems: response.totalItems,
          };
        })(),
      ];
    }
  }

  // DataService behaviour - single request with odata
  if (endpoint.dataType === BaseApi.DataService) {
    promisesArray = [
      (async (): Promise<IEntitiesResponse> => {
        const { $schema, value, totalItems } = await axios.request<IDataStoreArrayDto>({
          url: path,
          paramsSerializer: buildODataQuery,
          params: queryOptions,
          cancelToken: cancelToken?.token,
          method: method,
          data: body,
        });

        const schema = $schema ? await getSchema($schema) : {};

        if (!schema) {
          throw new Error('Failed to fetch schema for endpoint ');
        }

        const triggers = schema.cp_typeTriggers?.[TypeTrigger.OnAfterUiItemRead];
        if (!disableTriggers && Array.isArray(triggers) && triggers.length && Array.isArray(value) && value.length) {
          for (const item of value) {
            await executeUiTriggers<OnAfterUiItemReadExecutionContext>(
              {
                event: TypeTrigger.OnAfterUiItemRead,
                item: item,
              },
              triggers
            );
          }
        }

        return { entities: ensureItemIds(value as IDataItem[]), schema, totalItems };
      })(),
    ];
  }

  if (!promisesArray) {
    throw new Error(`Unknown endpoint data type ${endpoint.dataType}`);
  }

  if (!promisesArray.length) {
    console.warn(`No requests sent for ${path}. Endpoint: ${endpointId}.`);
  }

  return promisesArray;
}

// POST
export async function postEntityToEndpoint<T extends IDataItem = IDataItem>(
  endpointId: string,
  path: string,
  item: T,
  schema?: IJSONSchema
): Promise<T> {
  const endpoint = getEndpoint(endpointId);
  const accountNo = getAccountNo<IGlobalState>(({ auth }) => auth.user?.companies || [])(store.getState());

  const itemToAdd = cloneDeepWithMetadata(item);
  clearVirtualProperties(itemToAdd);
  cleanupRecursive(itemToAdd);

  const createTriggers = schema?.cp_typeTriggers?.[TypeTrigger.OnBeforeUiCreate];
  if (Array.isArray(createTriggers) && createTriggers.length && itemToAdd) {
    await executeUiTriggers<OnBeforeUiCreateExecutionContext>(
      {
        event: TypeTrigger.OnBeforeUiCreate,
        item: itemToAdd,
      },
      createTriggers
    );
  }

  const { value } = await endpoint.axios.post<IDataStoreItemDto<T>>(
    prepareActionLink(path, { ...itemToAdd, accountNo }, endpoint.dataType === BaseApi.ApiGateway),
    itemToAdd,
    {
      headers: {
        'x-cp-data-company': getCpDataCompany(),
      },
    }
  );

  const readTriggers = schema?.cp_typeTriggers?.[TypeTrigger.OnAfterUiItemRead];
  if (Array.isArray(readTriggers) && readTriggers.length && value) {
    await executeUiTriggers<OnAfterUiItemReadExecutionContext>(
      {
        event: TypeTrigger.OnAfterUiItemRead,
        item: value,
      },
      readTriggers
    );
  }

  return value;
}

// CLONE
export async function cloneEntityToEndpoint(endpointId: string, path: string, originalItem: IDataItem, schema?: IJSONSchema): Promise<IDataItem> {
  const endpoint = getEndpoint(endpointId);
  const accountNo = getAccountNo<IGlobalState>(({ auth }) => auth.user?.companies || [])(store.getState());

  const itemToClone = cloneDeepWithMetadata(originalItem);
  clearVirtualProperties(itemToClone);
  cleanupRecursive(itemToClone);

  const { identifier, ...item } = itemToClone;

  // ApiGateway behaviour - POST new item
  if (endpoint.dataType === BaseApi.ApiGateway) {
    return await postEntityToEndpoint(endpointId, path, item, schema);
  }

  // DataService behaviour - execute copy action
  if (endpoint.dataType === BaseApi.DataService) {
    const baseLink = prepareActionLink(path, { ...item, accountNo }, false);
    const baseLinkWithoutQuery = baseLink.indexOf('?') >= 0 ? baseLink.substring(0, baseLink.indexOf('?')) : baseLink;
    const baseLinkQuery = baseLink.indexOf('?') >= 0 ? baseLink.substring(baseLink.indexOf('?')) : '';

    const fullLink = urlJoin(baseLinkWithoutQuery, 'clone', baseLinkQuery);

    const { value } = await endpoint.axios.post<IDataStoreItemDto>(
      fullLink,
      {
        sourceItemIdentifier: identifier?.toString(),
      },
      {
        headers: {
          'x-cp-data-company': getCpDataCompany(),
        },
      }
    );
    return value;
  }

  throw new Error(`Unknown endpoint data type ${endpoint.dataType}`);
}

// PATCH
export async function patchEntityToEndpoint<T extends IDataItem = IDataItem>(
  endpointId: string,
  path: string,
  initialItem: T,
  patch: Partial<T>,
  schema?: IJSONSchema,
  headers?: { [key: string]: string }
): Promise<T> {
  return putEntityToEndpoint(endpointId, path, initialItem, patch, schema, true, headers);
}

// PUT
export async function putEntityToEndpoint<T extends IDataItem = IDataItem>(
  endpointId: string,
  path: string,
  initialItem: T,
  data: T | Partial<T>,
  schema?: IJSONSchema,
  patch: boolean = false,
  headers?: { [key: string]: string }
): Promise<T> {
  const endpoint = getEndpoint(endpointId);
  const accountNo = getAccountNo<IGlobalState>(({ auth }) => auth.user?.companies || [])(store.getState());

  const itemForUpdate = cloneDeepWithMetadata(data);
  clearVirtualProperties(itemForUpdate);
  cleanupRecursive(itemForUpdate);

  const updateTriggers = schema?.cp_typeTriggers?.[TypeTrigger.OnBeforeUiUpdate];
  if (Array.isArray(updateTriggers) && updateTriggers.length && initialItem) {
    await executeUiTriggers<OnBeforeUiUpdateExecutionContext>(
      {
        event: TypeTrigger.OnBeforeUiUpdate,
        oldItem: initialItem,
        newItem: itemForUpdate,
      },
      updateTriggers
    );
  }

  const baseLink = prepareActionLink(path, { ...itemForUpdate, accountNo }, endpoint.dataType === BaseApi.ApiGateway);
  const baseLinkWithoutQuery = baseLink.indexOf('?') >= 0 ? baseLink.substring(0, baseLink.indexOf('?')) : baseLink;
  const baseLinkQuery = baseLink.indexOf('?') >= 0 ? baseLink.substring(baseLink.indexOf('?')) : '';

  const fullLink = urlJoin(baseLinkWithoutQuery, encodeURIComponent(initialItem.identifier?.toString() || '-'), baseLinkQuery);

  const { value } = await endpoint.axios[patch ? 'patch' : 'put']<IDataStoreItemDto<T>>(fullLink, itemForUpdate, {
    headers: {
      'x-cp-data-company': getCpDataCompany(),
      ...(headers || {}),
    },
  });

  const readTriggers = schema?.cp_typeTriggers?.[TypeTrigger.OnAfterUiItemRead];
  if (Array.isArray(readTriggers) && readTriggers.length && value) {
    await executeUiTriggers<OnAfterUiItemReadExecutionContext>(
      {
        event: TypeTrigger.OnAfterUiItemRead,
        item: value,
      },
      readTriggers
    );
  }

  return value;
}

// DELETE
export async function deleteEntityFromEndpoint(endpointId: string, path: string, items: IDataItem[], schema?: IJSONSchema): Promise<void> {
  const endpoint = getEndpoint(endpointId);
  const accountNo = getAccountNo<IGlobalState>(({ auth }) => auth.user?.companies || [])(store.getState());

  const deleteTriggers = schema?.cp_typeTriggers?.[TypeTrigger.OnBeforeUiDelete];
  if (Array.isArray(deleteTriggers) && deleteTriggers.length && items) {
    await executeUiTriggers<OnBeforeUiDeleteExecutionContext>(
      {
        event: TypeTrigger.OnBeforeUiDelete,
        items: items,
      },
      deleteTriggers
    );
  }

  const identifiersToRemove = items.map(({ identifier }) => identifier).filter(Boolean) as string[];
  if (!identifiersToRemove.length) {
    throw new Error('Invalid items to remove. Items list must have items with identifiers.');
  }

  const filter = { or: identifiersToRemove.map((identifier) => ({ identifier: escapeIllegalOdataValueChars(identifier) })) };

  return endpoint.axios.delete(prepareActionLink(path, { accountNo }, true), {
    data: decodeURIComponent(buildODataQuery({ filter })),
    headers: {
      'x-cp-data-company': getCpDataCompany(),
    },
  });
}

// TODO: Remove fallback to 'guest' when guest@domain is available in email claim
const placeholderMap: Map<RegExp, () => string> = new Map([
  [
    /{{user}}|%7B%7Buser%7D%7D/g,
    () => {
      const user = store.getState().auth.user?.account?.email;
      return user ?? 'guest';
    },
  ],
  [
    /{{preferred_username}}|%7B%7Bpreferred_username%7D%7D/g,
    () => {
      const preferredUsername = store.getState().auth.user?.account?.preferred_username;
      return preferredUsername ?? 'guest';
    },
  ],
]);

export function replaceDataUrlPlaceholders(path: string): string {
  let replacedPath = path;
  for (const [placeholder, value] of placeholderMap) {
    try {
      replacedPath = replacedPath.replace(placeholder, encodeURIComponent(value()));
    } catch (err) {
      throw new Error(`Error replacing path placeholder ${placeholder}: ${err}`);
    }
  }
  return replacedPath;
}
