import { IJSONSchema, IRelatedLink, FieldTrigger, Schemas, OnLookupExecutionContext } from '@cp/base-types';
import { useLoadableData, usePowerUser } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { DynamicDataUrlFunction, IDataItem, IDataUrlDetails, IGenericComponentData, IScrollableContent, OrderDirection } from '@cpa/base-core/types';
import {
  DialogType,
  DirectionalHint,
  IconButton,
  IObjectWithKey,
  MessageBarType,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import Form, { ErrorSchema, Registry, WidgetProps } from '@rjsf/core';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { executeUiTriggers, getMatchingPageByDataUrl } from '@cpa/base-core/helpers';
import { cloneDeepWithMetadata, getIntermediateAnyOfSchema } from '@cp/base-utils';
import { axiosDictionary } from '@cpa/base-core/api';
import { EndpointContext, FormContext, ItemInfoContext, PathContext, TableKeyPrefix } from '@cpa/base-core/constants';

import { getSchemaUIOptions } from '../../../../../../screens/GenericScreen/utils';
import DrawerOrCallout from '../../../../../DrawerOrCallout/DrawerOrCallout';
import { IItemSelectionDialogRef, ItemSelectionDialog } from '../../../../../ItemSelectionDialog/ItemSelectionDialog';
import ScrollingContent from '../../../../../ScrollingContent/ScrollingContent';
import TitleField, { TitleFieldType } from '../../../TitleField/TitleField';
import DescriptionField from '../../../DescriptionField/DescriptionField';

import styles from './Suggestions.module.scss';

interface ISuggestionsProps extends WidgetProps {
  schema: IJSONSchema;
  uiProps: Record<string, unknown>;
  registry: Registry & {
    rootSchema?: IJSONSchema;
    rootFormData?: IDataItem;
    formRef?: Form<unknown>;
  };
  lookupLink: IRelatedLink;
  icon?: string;
  emptyValue?: boolean | number | string | object | null;
  parentSchema?: IJSONSchema;
  hideLabel?: boolean;
  onBlur: (id: string, value: boolean | number | string | null, item?: IDataItem) => void;
}

const getSuggestionPropertyJsonPath = (url: string): string => {
  try {
    const parsedUrl = new URL(decodeURIComponent(url), window.location.origin);
    return parsedUrl.searchParams.get('cp_suggestionPropertyJsonPath') || 'identifier';
  } catch (e) {
    return 'identifier';
  }
};

const searchBoxIcon = { iconName: undefined };

const Suggestions: React.FC<ISuggestionsProps> = ({
  registry,
  id,
  readonly,
  required,
  disabled,
  value,
  onChange,
  schema: fieldSchema,
  onBlur,
  onFocus,
  lookupLink,
  emptyValue,
  parentSchema,
  label,
  hideLabel,
  placeholder,
}) => {
  const { startAction, finishAction, page } = useContext(FormContext);
  const itemInfoContext = useContext(ItemInfoContext);
  const pathContextValue = useContext(PathContext);
  const intermediateAnyOfSchema = useMemo(
    () => (parentSchema ? getIntermediateAnyOfSchema(pathContextValue, parentSchema, fieldSchema) : undefined),
    [fieldSchema, parentSchema, pathContextValue]
  );

  const dialogLookup = parentSchema?.cp_ui?.dialogLookup || fieldSchema.cp_ui?.dialogLookup || intermediateAnyOfSchema?.cp_ui?.dialogLookup;

  const disableLookupAdvancedFilter =
    parentSchema?.cp_ui?.disableLookupAdvancedFilter ||
    fieldSchema.cp_ui?.disableLookupAdvancedFilter ||
    intermediateAnyOfSchema?.cp_ui?.disableLookupAdvancedFilter;

  const inputDisabled =
    disabled ||
    (fieldSchema as { disabled: boolean }).disabled ||
    readonly ||
    (
      fieldSchema as {
        readonly: boolean;
      }
    ).readonly;

  const advancedFilterEnabled = !disableLookupAdvancedFilter && !inputDisabled && !dialogLookup;

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const pages = useSelector((state: IGlobalState) => state.app.pages);
  const powerUser = usePowerUser();
  const tableRef = useRef<IScrollableContent>(null);
  const [t] = useTranslation();
  const endpointIdentifierFromContext = useContext(EndpointContext);
  const endpointIdentifier = lookupLink.endpoint || endpointIdentifierFromContext;

  // Input width
  const inputFieldDivRef = useRef<HTMLDivElement>(null);

  const [isCalloutVisible, { setTrue: showCallout, setFalse: hideCallout }] = useBoolean(false);

  const selectedValue = useRef(value);
  const selectedItem = useRef<IDataItem | null>(null);

  // Data
  const [virtualData, setVirtualData] = useState<{ schema: IJSONSchema; items: IDataItem[]; totalItems: number }>();
  const getDataUrl = useCallback(async (): Promise<IDataUrlDetails | null> => {
    const clonedLink = cloneDeepWithMetadata(lookupLink);

    const triggers =
      parentSchema?.cp_fieldTriggers?.[FieldTrigger.OnLookup] ||
      fieldSchema?.cp_fieldTriggers?.[FieldTrigger.OnLookup] ||
      intermediateAnyOfSchema?.cp_fieldTriggers?.[FieldTrigger.OnLookup];
    const fieldName = (id.startsWith('root_') ? id.slice(5) : id).split('_').slice(-2, -1)[0];
    let disabledParenting: boolean = false;
    let disabledRequest: boolean = false;
    if (Array.isArray(triggers) && triggers.length) {
      await executeUiTriggers<OnLookupExecutionContext>(
        {
          event: FieldTrigger.OnLookup,
          showMessage: itemInfoContext?.showMessage,
          lookupLink: clonedLink,
          fieldSchema: fieldSchema,
          fieldName: fieldName,
          fieldPath: pathContextValue,
          schema: registry?.rootSchema,
          page: page,
          rootFormData: registry?.rootFormData,
          isCreate: itemInfoContext?.type === 'add',
          isUpdate: itemInfoContext?.type === 'edit',
          disableParenting() {
            disabledParenting = true;
          },
          disableRequest() {
            disabledRequest = true;
          },
          setVirtualData(newVirtualData: typeof virtualData) {
            disabledRequest = true;
            setVirtualData(newVirtualData);
          },
          get formData(): object {
            return _.cloneDeep((registry?.formRef?.state as { formData?: {} })?.formData || {});
          },
          setFormData: (formData: object) => {
            if (!registry?.formRef?.setState) {
              console.warn('Failed to change form data from trigger, missing setState', { registry });
              return;
            }
            registry.formRef.setState({
              formData,
            });
            registry.formRef.onChange(
              formData,
              (
                registry.formRef.state as {
                  errorSchema: ErrorSchema;
                }
              ).errorSchema || {}
            );
          },
        },
        triggers
      );
    }

    if (disabledRequest) {
      return null;
    }

    setVirtualData(undefined);
    const url = new URL(clonedLink.href, window.location.origin);
    return { url: url.pathname + url.search, disableParenting: disabledParenting };
  }, [
    intermediateAnyOfSchema,
    lookupLink,
    parentSchema,
    fieldSchema,
    pathContextValue,
    id,
    itemInfoContext?.showMessage,
    itemInfoContext?.type,
    registry,
    page,
  ]);

  const matchedPage = useMemo(() => {
    const url = new URL(lookupLink.href, window.location.origin);
    return getMatchingPageByDataUrl(url.pathname + url.search, pages)?.matched;
  }, [lookupLink.href, pages]);

  const {
    loadItems,
    isFetching: isFetchingLoadable,
    items: itemsLoadable,
    totalItems: totalItemsLoadable,
    schema: schemaLoadable,
    errors,
    isODataSupportedByEndpoint,
  } = useLoadableData(
    getDataUrl,
    endpointIdentifier || axiosDictionary.appDataService,
    matchedPage?.groupPropertyJsonPath,
    matchedPage?.schemaUrl as string | undefined
  );

  useEffect(() => {
    if (errors.length && itemInfoContext) {
      for (const error of errors) {
        itemInfoContext.showMessage(error, MessageBarType.error);
      }
    }
  }, [errors, itemInfoContext]);

  const isFetching = virtualData ? false : isFetchingLoadable;
  const items = virtualData?.items ?? itemsLoadable;
  const totalItems = virtualData?.totalItems ?? totalItemsLoadable;
  const schema = virtualData?.schema ?? schemaLoadable;

  const uiOptions = useMemo(() => getSchemaUIOptions(schema), [schema]);

  const fallbackPage = useMemo(() => {
    return {
      identifier: 'suggestions-table',
      name: schema?.title || 'Suggestions',
      dataEndpoint: endpointIdentifier
        ? {
            identifier: endpointIdentifier,
          }
        : undefined,
    } as Schemas.CpaPage;
  }, [schema, endpointIdentifier]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchText = useCallback(
    _.debounce((v: string) => {
      tableRef.current?.setSearchText(v);
    }, 500),
    []
  );

  useEffect(() => {
    // Initial blur call
    if (value && typeof value === 'string') {
      onBlur?.(id, value, selectedItem.current || undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointIdentifier, lookupLink.href]);

  const windowBlurred = useRef(false);
  const focusHandlingDisabled = useRef(false);
  const onBoxFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (focusHandlingDisabled.current || windowBlurred.current) {
        if (focusHandlingDisabled.current) {
          event.target.blur();
        }
        focusHandlingDisabled.current = false;
        windowBlurred.current = false;
        return;
      }

      // On callout open

      loadItems(
        {
          top: 10,
          orderBy: matchedPage?.initialOrderPropertyJsonPath
            ? [[matchedPage.initialOrderPropertyJsonPath, matchedPage.initialOrderDirection === OrderDirection.DESCENDING ? 'desc' : 'asc']]
            : undefined,
        },
        { overwriteExistingData: true }
      );

      showCallout();
      onFocus?.(id, value);
    },
    [loadItems, matchedPage?.initialOrderPropertyJsonPath, matchedPage?.initialOrderDirection, showCallout, onFocus, id, value]
  );

  const onBoxBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (focusHandlingDisabled.current) {
        return;
      }

      if (event.target === document.activeElement) {
        windowBlurred.current = true;
        return;
      }

      // Let form state update after changes
      // Issue example:
      // OnChange with empty value -> SetState -> OnBlur which takes old value -> SetState -> React StateUpdate with state from OnBlur)
      startAction();
      setTimeout(async () => {
        try {
          await onBlur?.(id, selectedValue.current, selectedItem.current || undefined);
        } finally {
          finishAction();
        }
      }, 150);
    },
    [startAction, onBlur, id, finishAction]
  );

  const onBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, inputValue: string = '') => {
      setSearchText(inputValue);

      selectedItem.current = null;
      selectedValue.current = inputValue || emptyValue;
      onChange(inputValue || emptyValue);

      if (!isCalloutVisible && !dialogLookup) {
        showCallout();
      }
    },
    [setSearchText, emptyValue, onChange, isCalloutVisible, dialogLookup, showCallout]
  );

  const handleItemClick = useCallback(
    (item: IDataItem, selection?: Selection<IObjectWithKey & IDataItem>, schema?: IJSONSchema | null, disableFocus: boolean = true) => {
      if (!item?.identifier) {
        return;
      }

      const field = getSuggestionPropertyJsonPath(lookupLink.href);
      setSearchText(_.get(item, field) as string);
      selectedValue.current = _.get(item, field);
      selectedItem.current = item;
      onChange(_.get(item, field));

      hideCallout();

      if (disableFocus) {
        focusHandlingDisabled.current = true;
        inputFieldDivRef.current?.blur();
      }
    },
    [lookupLink.href, setSearchText, onChange, hideCallout]
  );

  const handleKeyDown = useCallback(() => {
    /* Need to keep it empty to prevent the callout from being closed by escape */
  }, []);

  const tableData: IGenericComponentData = useMemo(
    () => ({
      items: items,
      schema: schema,
      isFetching: isFetching,
      totalItems: totalItems,
      page: matchedPage || fallbackPage,
    }),
    [fallbackPage, isFetching, items, matchedPage, schema, totalItems]
  );

  const calloutContent = useCallback(() => {
    if (!schema && isFetching) {
      return (
        <div className={styles.loader}>
          <Spinner size={SpinnerSize.large} />
        </div>
      );
    }

    if (!schema) {
      return null;
    }
    return (
      <aside>
        <div style={{ padding: 0, zIndex: 1000 }} className={darkMode ? styles.suggestionsDark : styles.suggestions}>
          <ScrollingContent
            tableKey={`${TableKeyPrefix.Suggestions}.${schema.$id || 'default'}`}
            ref={tableRef}
            hideHeader={false}
            hideActions={true}
            hideSelectedLabel={true}
            disableDoubleClick={true}
            selectionMode={SelectionMode.none}
            hideFilter={true}
            isWidget={true}
            pageSize={10}
            loadItems={loadItems}
            hiddenInTable={uiOptions.hiddenInTable}
            onItemClick={handleItemClick}
            disabledManagedColumnsWidth={false}
            parentPropertyJsonPath={parentSchema?.cp_parentPropertyJsonPath ?? schema?.cp_parentPropertyJsonPath}
            isODataSupportedByEndpoint={isODataSupportedByEndpoint}
            data={tableData}
            disableDragDrop={true}
            disableAddButtonInTable={true}
          />
        </div>
      </aside>
    );
  }, [
    isFetching,
    schema,
    darkMode,
    loadItems,
    uiOptions.hiddenInTable,
    handleItemClick,
    parentSchema?.cp_parentPropertyJsonPath,
    isODataSupportedByEndpoint,
    tableData,
  ]);

  const itemSelectionDialogRef = useRef<IItemSelectionDialogRef>(null);
  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: label || t('common.relation'),
    }),
    [label, t]
  );
  const openItemSelectionDialog = useCallback((event?: React.MouseEvent<unknown>) => {
    event?.preventDefault();

    itemSelectionDialogRef.current?.openDialog();
  }, []);

  const handleEnterKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (dialogLookup && (event.key === 'Enter' || event.key === ' ')) {
        openItemSelectionDialog();
      }
    },
    [openItemSelectionDialog, dialogLookup]
  );

  const itemSelectionDialogPage = useMemo(
    () =>
      ({
        ...(matchedPage || fallbackPage),
        dynamicDataUrl: getDataUrl,
      } as Schemas.CpaPage & { dynamicDataUrl?: DynamicDataUrlFunction }),
    [fallbackPage, getDataUrl, matchedPage]
  );
  const handleItemSelectionDialogSelect = useCallback(
    (item: IDataItem) => {
      handleItemClick(item, undefined, schema, !!dialogLookup);
    },
    [dialogLookup, handleItemClick, schema]
  );

  const input = useCallback(() => {
    const eventHandlingProps = dialogLookup
      ? {
          onClick: openItemSelectionDialog,
          style: {
            cursor: 'pointer',
          },
          onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();
          },
        }
      : {
          onChange: onBoxChange,
          onFocus: onBoxFocus,
          onBlur: onBoxBlur,
        };

    return (
      <div ref={inputFieldDivRef} style={{ display: 'flex' }}>
        <div
          style={{
            flex: 1,
          }}
          tabIndex={dialogLookup ? 0 : undefined}
          onKeyDown={handleEnterKeyDown}
          className={classNames({ [styles.input]: isCalloutVisible })}
        >
          <SearchBox
            disabled={inputDisabled}
            autoComplete={'off'}
            spellCheck={false}
            value={value}
            iconProps={searchBoxIcon}
            disableAnimation={true}
            onEscape={handleKeyDown}
            title={label || fieldSchema.title}
            placeholder={placeholder}
            {...eventHandlingProps}
          />
        </div>
        {advancedFilterEnabled && powerUser && (
          <IconButton
            iconProps={{
              iconName: 'QueryList',
              style: {
                color: darkMode ? '#fefefe' : '#383838',
              },
            }}
            onClick={openItemSelectionDialog}
          />
        )}
      </div>
    );
  }, [
    powerUser,
    handleEnterKeyDown,
    isCalloutVisible,
    inputDisabled,
    value,
    onBoxChange,
    onBoxFocus,
    onBoxBlur,
    handleKeyDown,
    label,
    fieldSchema.title,
    placeholder,
    dialogLookup,
    openItemSelectionDialog,
    advancedFilterEnabled,
    darkMode,
  ]);

  const calloutProps = useMemo(
    () => ({
      hidden: !isCalloutVisible || items.length === 0,
      target: inputFieldDivRef as unknown as React.RefObject<Element>,
      isBeakVisible: false,
      gapSpace: 2,
      directionalHint: DirectionalHint.bottomLeftEdge,
      onDismiss: hideCallout,
      setInitialFocus: false,
      preventDismissOnScroll: false,
      calloutWidth: inputFieldDivRef.current?.getBoundingClientRect().width,
      shouldUpdateWhenHidden: true,
      className: styles.callout,
    }),
    [items.length, isCalloutVisible, hideCallout]
  );

  const handleDrawerClose = useCallback(() => {
    focusHandlingDisabled.current = true;
    hideCallout();
  }, [hideCallout]);

  const itemSelectionDialogGenericScreenProps = useMemo(
    () => ({
      genericComponentProps: { scrollingContentProps: { defaultFilterExpanded: dialogLookup } },
    }),
    [dialogLookup]
  );

  return (
    <div className={classNames(styles.wrapper)}>
      {!hideLabel && (
        <TitleField
          title={label || fieldSchema.title}
          localizable={fieldSchema?.cp_localizable}
          schema={fieldSchema}
          required={required}
          registry={registry}
          type={TitleFieldType.Primitive}
        />
      )}
      <DescriptionField description={fieldSchema.description} />
      <DrawerOrCallout
        title={t('common.suggestion')}
        hintText={t('common.suggestionHint')}
        renderBody={calloutContent}
        renderInput={input}
        calloutProps={calloutProps}
        isOpened={isCalloutVisible}
        onDrawerClose={handleDrawerClose}
      />
      <ItemSelectionDialog
        ref={itemSelectionDialogRef}
        page={itemSelectionDialogPage}
        onSubmit={handleItemSelectionDialogSelect}
        dialogContentProps={dialogContentProps}
        genericScreenProps={itemSelectionDialogGenericScreenProps}
      />
    </div>
  );
};

export default Suggestions;
