import { bindGlobalConsole, ConsoleConnection } from '@cp/base-logging';
import { EnvironmentType, LogLevel } from '@cp/base-types';
import { initializeCpIcons } from '@cpa/base-icons';
import { Environment } from '@cpa/base-core/app/environment';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { ConnectedRouter, push } from 'connected-react-router';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { history, i18n } from '@cpa/base-core/app';
import { StorageKey } from '@cpa/base-core/constants';
import { runSaga, store } from '@cpa/base-core/store';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@cpa/ui-components/dist/style.css';
import * as _ from 'lodash';
import { ThemeProvider } from '@fluentui/react';
import { apm } from '@elastic/apm-rum';
import { configure, ImageProps, LinkProps, initializeIcons as initializeUiComponentIcons } from '@cpa/ui-components';

import Routes from './components/Routes/Routes';
import {
  Card,
  CustomCardTemplates,
  CustomRowTemplates,
  IUserAgreement,
  Screen,
  ScreenTypesConfiguration,
  SingleItem,
  SingleItemTemplates,
  TableRow,
  UserAgreements,
} from './mapping';
import AppErrors from './components/AppErrors/AppErrors';

export interface IAppOptions {
  mapping?: {
    screens?: Record<string, Screen>;
    cardTemplates?: Record<string, Card>;
    tableRowTemplates?: Record<string, TableRow>;
    singleItemTemplates?: Record<string, SingleItem>;
  };
  overwriteAgreements?: (defaultAgreements: IUserAgreement[]) => IUserAgreement[];
}

export function runApp(element: string, options: IAppOptions = {}): void {
  if (Environment.env.REACT_APP_ELASTIC_APM_ENDPOINT) {
    // Elastic APM
    apm.init({
      apiVersion: 3,
      serverUrl: Environment.env.REACT_APP_ELASTIC_APM_ENDPOINT,
      serviceName: Environment.env.REACT_APP_CPA_NAME,
      environment: Environment.env.REACT_APP_ENVIRONMENT,
      serviceVersion: Environment.env.REACT_APP_VERSION + (Environment.env.REACT_APP_BUILD_TIME ? '_' + Environment.env.REACT_APP_BUILD_TIME : ''),
      logLevel: Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Local ? 'debug' : 'warn',
      breakdownMetrics: true,
      transactionSampleRate: 1.0,
      disableInstrumentations: ['history'],
    });
    apm.setCustomContext({ language: localStorage?.getItem('i18nextLng') || undefined });
  } else {
    console.warn(`Missing elastic APM endpoint. Initialization skipped.`);
  }

  runSaga();

  configure({
    components: {
      Link: (props: LinkProps) => {
        const r = new RegExp('^(?:[a-z+]+:)?//', 'i');
        if (!props.href || r.test(props.href as string)) {
          return <a {...props} target={'_blank'} rel={'noreferrer'} />;
        } else {
          return (
            <a
              {...props}
              onClick={(e) => {
                e.preventDefault();
                store.dispatch(
                  push({
                    pathname: props.href,
                  })
                );
              }}
            />
          );
        }
      },
      Image: (props: ImageProps) => <img {...props} />,
    },
  });

  if (options.mapping?.screens) {
    _.merge(ScreenTypesConfiguration, options.mapping.screens);
  }
  if (options.mapping?.cardTemplates) {
    _.merge(CustomCardTemplates, options.mapping.cardTemplates);
  }
  if (options.mapping?.tableRowTemplates) {
    _.merge(CustomRowTemplates, options.mapping.tableRowTemplates);
  }
  if (options.mapping?.singleItemTemplates) {
    _.merge(SingleItemTemplates, options.mapping.singleItemTemplates);
  }

  // Example:
  // { overwriteAgreements: (defaultAgreements) => [...defaultAgreements, { fieldName: 'customKey', title: 'Custom Key' }] }
  if (options.overwriteAgreements) {
    const newAgreements = options.overwriteAgreements(UserAgreements).slice();
    UserAgreements.length = 0;
    UserAgreements.push(...newAgreements);

    _.merge(
      StorageKey,
      UserAgreements.map(({ fieldName }) => fieldName)
        .filter((fieldName) => !Object.values(StorageKey).includes(fieldName))
        .reduce((acc, fieldName) => ({ ...acc, [fieldName]: fieldName }), {})
    );
  }

  initializeFileTypeIcons();
  initializeCpIcons();
  initializeUiComponentIcons();

  bindGlobalConsole({
    connections: new ConsoleConnection(Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Prod ? LogLevel.Info : LogLevel.Debug),
    name: Environment.env.REACT_APP_CPA_NAME,
  });

  // Special treatment used by some components
  // Search for 'cpaSpecialHistoryHandling' in components
  window.addEventListener('popstate', () => {
    if (window.history.cpaSpecialHistoryHandling) {
      // Hashchange event and replace location are covered here
      if (window.history.state?.replaceLocationWithUrl) {
        // Replace location
        window.history.replaceState(null, '', new URL(window.history.state?.replaceLocationWithUrl));
      }
      window.location.reload();
    }
  });

  const container = document.getElementById(element);
  const root = createRoot(container!);
  root.render(
    <Provider store={store}>
      <Suspense fallback={<AppErrors />}>
        <AppErrors />
        <I18nextProvider i18n={i18n}>
          <ThemeProvider>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </ThemeProvider>
        </I18nextProvider>
      </Suspense>
    </Provider>
  );
}
