import { Action } from 'redux-actions';
import { call, take, takeEvery } from 'redux-saga/effects';
import { io, Socket } from 'socket.io-client';
import urlJoin from 'url-join';
import { BatchOperationResult, DataOperation, SubscriptionNegotiationEvents, TypeConstants } from '@cp/base-types';

import { store } from '..';
import { axiosDictionary, getAccessToken, getEndpoint } from '../../api';
import { authReady, nameSpaceChange } from '../app/actions';
import { IDataItem } from '../../types';
import { settingsChanged } from '../settings/actions';

import {
  wsBatchOperationStatus,
  wsConnected,
  wsDisconnected,
  wsEntityModification,
  wsSubscribeToBatchOperationStatus,
  wsSubscribeToEntityModification,
} from './actions';

let socket: Socket;

const typeSubscriptions: Set<string> = new Set();
const batchOperationSubscriptions: Set<string> = new Set();

function initWebsocket(): void {
  const endpoint = getEndpoint(axiosDictionary.appDataService);
  const wsUrl: URL = new URL(urlJoin(endpoint.url, 'ws'), window.location.origin);
  socket = io(`${wsUrl.protocol}//${wsUrl.host}`, {
    autoConnect: false,
    upgrade: true,
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    transportOptions: {
      withCredentials: endpoint.withCredentials === true,
    },
    path: wsUrl.pathname,
  });

  // Error
  socket.io.on('error', (e: Error) => {
    console.error(`Error connecting to websocket`, e);
  });

  // Connect
  socket.on('connect', () => {
    store.dispatch(wsConnected());
  });

  // Disconnect
  socket.on('disconnect', () => {
    store.dispatch(wsDisconnected());
  });

  // Reconnect
  socket.io.on('reconnect', () => {
    console.info(`Reconnected to websocket server. Subscribing to ${typeSubscriptions.size} entities.`);
    for (const subscription of Array.from(typeSubscriptions.values())) {
      store.dispatch(wsSubscribeToEntityModification(subscription));
    }
    console.info(`Reconnected to websocket server. Subscribing to ${batchOperationSubscriptions.size} BatchOperations.`);
    for (const subscription of Array.from(batchOperationSubscriptions.values())) {
      store.dispatch(wsSubscribeToBatchOperationStatus(subscription));
    }
  });

  // Subscription confirmation
  socket.on('SUBSCRIBE__ENTITY_MODIFICATION', (data: { status: string; error?: string; subjectUri: string }) => {
    if (data.status === 'Ok') {
      console.debug(`Subscribed to entity modification - ${data.subjectUri}`);
    } else {
      console.error('Failed to subscribe to entity modification', data);
    }
  });

  // Subscription confirmation
  socket.on(SubscriptionNegotiationEvents.SUBSCRIBE_BATCH_OPERATION_STATUS, (data: { status: string; error?: string; identifier: string }) => {
    if (data.status === 'Ok') {
      console.debug(`Subscribed to batch operation Updates - ${data.identifier}`);
    } else {
      console.error('Failed to subscribe to entity modification', data);
    }
  });

  // Entity modification
  socket.on('ENTITY_MODIFICATION', (data: { subjectUri: string; affectedItems: IDataItem[]; timestamp: number; operation: DataOperation }) => {
    console.debug(
      `Detected entity modification ${data.subjectUri}. Affected items: ${data.affectedItems.length}. Timestamp: ${data.timestamp}. Operation: ${data.operation}.`
    );
    if (data.subjectUri === TypeConstants.CpNamespace) {
      store.dispatch(nameSpaceChange());
    }
    if (data.subjectUri === TypeConstants.UserProfileShadow && data.affectedItems[0].identifier === store.getState().auth.user?.account.email) {
      store.dispatch(settingsChanged());
    }
    store.dispatch(wsEntityModification(data));
  });

  // BatchOperation update modification
  socket.on('BATCH_OPERATION_STATUS', (data: { identifier: string; state: BatchOperationResult; timestamp: number }) => {
    console.debug(`Detected update ${data.identifier}. Timestamp: ${data.timestamp}.`);
    store.dispatch(wsBatchOperationStatus(data));
  });

  // Start connection
  console.debug(`Connecting to websocket - ${wsUrl}`, socket.io.opts);
  socket.connect();
}

function* subscribeToEntityModification({ payload }: Action<string>): Generator {
  if (!payload) {
    return;
  }

  let token: string | undefined = undefined;
  try {
    token = (yield call(getAccessToken)) as string;
  } catch (e) {}

  console.debug(`Subscribing to '${payload}' updates`);
  socket.emit('SUBSCRIBE__ENTITY_MODIFICATION', {
    subjectUri: payload,
    token: token,
  });
  typeSubscriptions.add(payload);
}

function* subscribeToBatchOperation({ payload }: Action<string>): Generator {
  if (!payload) {
    return;
  }

  let token: string | undefined = undefined;
  try {
    token = (yield call(getAccessToken)) as string;
  } catch (e) {}

  console.debug(`Subscribing to BatchOperation '${payload}' updates`);
  socket.emit(SubscriptionNegotiationEvents.SUBSCRIBE_BATCH_OPERATION_STATUS, {
    identifier: payload,
    token: token,
  });
  batchOperationSubscriptions.add(payload);
}

export default function* (): Generator {
  yield take(authReady);
  initWebsocket();

  yield takeEvery(wsSubscribeToEntityModification, subscribeToEntityModification);
  yield takeEvery(wsSubscribeToBatchOperationStatus, subscribeToBatchOperation);
}
