export const StorageKey: Record<string, string> = {
  HideCookie: 'hideCookie',
  AcceptGA: 'acceptGA',
  AcceptGAWithUserId: 'acceptGAWithUserId',
  DarkMode: 'darkMode',
  HideGreetings: 'hideGreetings',
  HideBubbles: 'hideBubbles',
  ZebraRows: 'zebraRows',
  DisableAnimation: 'disableAnimation',
  UseOnlyHorizontalMenu: 'useOnlyHorizontalMenu',
  DataLanguage: 'dataLanguage',
  HideDescriptionsInForm: 'hideDescriptionsInForm',
  EditFormsAsJson: 'editFormsAsJson',
  ExpertMode: 'expertMode',
  HighContrast: 'highContrast',
};
